<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="buttonBackClicked"
              v-bind:disabled="loading"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b> Заказ программы питания </b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div v-if="sended">
        <div class="p-2 mb-3">
          Мы свяжемся с Вами по телефону <b>{{ phone.value }}</b
          >.
        </div>
        <button
          class="btn btn-lg btn-primary btn-block"
          v-on:click="buttonOpenJournalClicked"
        >
          Вернуться в дневник
        </button>
      </div>
      <div v-else>
        <div class="p-2 mb-3">
          Введите номер телефона, по которому мы сможем связаться с Вами.
        </div>
        <form v-on:submit.prevent="onSubmit">
          <div class="form-group">
            <div class="input-group">
              <input
                class="form-control"
                v-bind:class="{ 'is-invalid': phone.error }"
                v-model.trim="phone.value"
                placeholder="Номер телефона"
                v-on:keyup="keyUpPhone"
                name="phone"
                v-bind:disabled="loading"
              />
              <div class="invalid-feedback" v-if="phone.error">
                {{ phone.error }}
              </div>
            </div>
          </div>
          <div class="form-group mt-3 mb-3">
            <button
              v-bind:disabled="loading"
              type="submit"
              class="btn btn-lg btn-primary btn-block"
            >
              <span
                v-if="loading"
                class="spinner-border"
                style="width: 1.25rem; height: 1.25rem"
              ></span>
              <span v-else>Далее</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "AccountNutritionProgram",
  mixins: [apiMixin],
  data: function () {
    return {
      phone: {
        value: null,
        timer: null,
        error: null,
      },
      sended: false,
      loading: false,
    };
  },
  methods: {
    keyUpPhone: function () {
      this.phone.error = null;
      clearTimeout(this.phone.timer);
      this.phone.timer = setTimeout(() => {
        this.validatePhone();
      }, 700);
    },
    validatePhone: function () {
      if (!this.phone.value) {
        this.phone.error = "Необходимо ввести номер телефона";
        return false;
      }
      this.phone.error = null;
      return true;
    },
    onSubmit: function () {
      let isValidPhone = this.validatePhone();
      if (!isValidPhone) {
        return;
      }
      this.loading = true;
      this.apiPost("/api/account-api/phone-order-nutrition-program", {
        phone: this.phone.value,
      })
        .then((response) => {
          if (response.data.status === "phone_error") {
            this.phone.error = response.data.error;
            this.loading = false;
            return;
          }
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          this.sended = true;
          this.loading = false;
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    buttonBackClicked: function () {
      if (this.sended) {
        this.sended = false;
        return;
      }
      this.$router.push({
        path: "/journal",
      });
    },
    buttonOpenJournalClicked: function () {
      this.$router.push({
        path: "/journal",
      });
    },
  },
  mounted: function () {
    this.phone.value = this.$route.query.phone;
  },
};
</script>
