<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Просмотр записи</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div key="if" v-if="food !== null">
          <div class="p-2 mb-3">
            <span class="mr-1" v-if="food.name !== ''">{{ food.name }}</span>
            <span class="text-muted"
              >{{ food.servingWeight.toFixed(0) }}&nbsp;г</span
            >
            <div class="product-info">
              <span class="text-muted mr-2">Б:&nbsp;{{ proteinPercent }}%</span>
              <span class="text-muted mr-2"
                >Ж:&nbsp;{{ fatTotalPercent }}%</span
              >
              <span class="text-muted"
                >У:&nbsp;{{ carbohydratesPercent }}%</span
              >
            </div>
            <div class="progress mt-1" style="height: 7px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                v-bind:style="{ width: proteinPercent + '%' }"
                v-bind:aria-valuenow="proteinPercent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                v-bind:style="{ width: fatTotalPercent + '%' }"
                v-bind:aria-valuenow="fatTotalPercent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-success"
                role="progressbar"
                v-bind:style="{ width: carbohydratesPercent + '%' }"
                v-bind:aria-valuenow="carbohydratesPercent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div v-for="(nutrient, index) in mainNutrientList" v-bind:key="index">
            <div class="d-flex p-2 mb-3">
              <span>{{ nutrient.label }}</span>
              <b class="ml-auto pl-1"
                >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
              >
            </div>
          </div>
          <div class="p-2 mb-3" v-if="vitaminNutrientList.length > 0">
            <b>Витамины</b>
          </div>
          <div
            v-for="(nutrient, index) in vitaminNutrientList"
            v-bind:key="index"
          >
            <div class="d-flex p-2 mb-3">
              <span>{{ nutrient.label }}</span>
              <b class="ml-auto pl-1"
                >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
              >
            </div>
          </div>
          <div class="p-2 mb-3" v-if="mineralNutrientList.length > 0">
            <b>Минералы</b>
          </div>
          <div
            v-for="(nutrient, index) in mineralNutrientList"
            v-bind:key="index"
          >
            <div class="d-flex p-2 mb-3">
              <span>{{ nutrient.label }}</span>
              <b class="ml-auto pl-1"
                >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
              >
            </div>
          </div>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
      <div
        class="fixed-bottom d-flex justify-content-center border-top bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <div
              class="d-flex align-items-center"
              v-on:click="onDeleteButtonClicked"
              style="cursor: pointer"
            >
              <img
                src="@/assets/image/icon/trash-left.svg"
                width="24px"
                height="24px"
              />
            </div>
            <div
              class="d-flex align-items-center"
              v-on:click="onCopyButtonClicked"
              style="cursor: pointer"
            >
              <img
                src="@/assets/image/icon/copy.svg"
                width="24px"
                height="24px"
              />
            </div>
            <div
              class="d-flex align-items-center"
              v-on:click="onEditButtonClicked"
              style="cursor: pointer"
            >
              <img
                src="@/assets/image/icon/edit.svg"
                width="24px"
                height="24px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { apiMixin } from "@/mixins/api";

export default {
  name: "JournalShow",
  mixins: [productMixin, apiMixin],
  data: function () {
    return {
      loadSpinnerVisible: false,
      food: null,
    };
  },
  methods: {
    createNutrientList: function (object) {
      let list = [];
      for (let nutrientKey in object) {
        let nutrient = object[nutrientKey];
        let value = ((this.food.servingWeight / 100) * nutrient.value)
          .toFixed(nutrient.precision)
          .replace(/^(\d+(\.[1-9]+)?)(.*)/, "$1");
        if (value === "0") {
          continue;
        }
        list.push({
          label: nutrient.label,
          unitLabel: nutrient.unitLabel,
          value: value,
        });
      }
      return list;
    },
    onDeleteButtonClicked: function () {
      if (!this.food.name) {
        return;
      }
      this.$router.push({
        path: "/journal/delete",
        query: {
          id: this.$route.query.id,
          name: this.food.name,
        },
      });
    },
    onCopyButtonClicked: function () {
      this.$router.push({
        path: "/journal/copy",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    onEditButtonClicked: function () {
      this.$router.push({
        path: "/journal/edit",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    back: function () {
      this.$router.push({
        path: "/journal",
        query: {
          id: this.$route.query.id,
          date: this.$route.query.date,
        },
      });
    },
  },
  computed: {
    energyConsumed: function () {
      let energyConsumed = 0;
      if (
        this.food.nutrient !== undefined &&
        this.food.nutrient.main !== undefined &&
        this.food.nutrient.main.energyConsumed !== undefined
      ) {
        energyConsumed = this.getEnergyConsumed(
          this.food.nutrient.main.energyConsumed.value,
        );
      }
      return Math.round(energyConsumed);
    },
    proteinPercent: function () {
      if (this.energyConsumed === 0 || !this.food.nutrient.main.protein) {
        return 0;
      }
      return Math.round(
        (this.getProteinEnergy(this.food.nutrient.main.protein.value) /
          this.energyConsumed) *
          100,
      );
    },
    fatTotalPercent: function () {
      if (this.energyConsumed === 0 || !this.food.nutrient.main.fatTotal) {
        return 0;
      }
      return Math.round(
        (this.getFatTotalEnergy(this.food.nutrient.main.fatTotal.value) /
          this.energyConsumed) *
          100,
      );
    },
    carbohydratesPercent: function () {
      if (this.energyConsumed === 0 || !this.food.nutrient.main.carbohydrates) {
        return 0;
      }
      return Math.round(
        (this.getCarbohydratesEnergy(
          this.food.nutrient.main.carbohydrates.value,
        ) /
          this.energyConsumed) *
          100,
      );
    },
    mainNutrientList: function () {
      return this.createNutrientList(this.food.nutrient.main);
    },
    vitaminNutrientList: function () {
      return this.createNutrientList(this.food.nutrient.vitamin);
    },
    mineralNutrientList: function () {
      return this.createNutrientList(this.food.nutrient.mineral);
    },
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    let id = this.$route.query.id;
    if (!id) {
      window.location.href = "/journal";
      return;
    }
    this.apiGet("/api/journal-product-api/get", {
      params: {
        id: id,
      },
    })
      .then((response) => {
        if (response.data.success !== true) {
          window.location.href = "/journal";
          return;
        }
        this.food = response.data.data;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
