<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <form v-on:submit.prevent="onSubmit">
        <div
          class="fixed-top d-flex justify-content-center border-bottom bg-white"
          style="opacity: 0.95"
        >
          <div class="col-12 p-0" style="max-width: 500px">
            <div
              class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
            >
              <button
                class="text-decoration-none text-reset btn p-0"
                v-on:click="back"
                v-bind:disabled="loading"
              >
                <img
                  src="@/assets/image/icon/chevron-left.svg"
                  width="24px"
                  height="24px"
                />
              </button>
              <b>Копирование записи</b>
              <span
                v-if="loading"
                class="spinner-border"
                style="width: 24px; height: 24px"
              ></span>
              <button
                v-else
                class="text-decoration-none text-reset btn p-0"
                v-on:click="onSubmit"
              >
                <img
                  src="@/assets/image/icon/check.svg"
                  width="24px"
                  height="24px"
                />
              </button>
            </div>
          </div>
        </div>
        <Transition name="fade">
          <div key="if" v-if="inited">
            <div v-for="(element, index) in element.main" v-bind:key="index">
              <common-element-input
                v-bind:element="element"
                v-bind:loadingFlag="loading"
                v-bind:onKeyUp="keyUp"
              ></common-element-input>
            </div>
            <div class="form-group mt-3">
              <button
                v-bind:disabled="loading"
                type="submit"
                class="btn btn-lg btn-primary btn-block"
              >
                <span
                  v-if="loading"
                  class="spinner-border"
                  style="width: 1.25rem; height: 1.25rem"
                ></span>
                <span v-else>Записать</span>
              </button>
            </div>
          </div>
          <div key="else" class="d-flex justify-content-center" v-else>
            <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
              <span class="sr-only">Загрузка...</span>
            </div>
          </div>
        </Transition>
      </form>
    </div>
  </div>
</template>

<script>
import CommonElementInput from "@/components/common/ElementInput.vue";
import { apiMixin } from "@/mixins/api";

export default {
  name: "JournalCopy",
  components: {
    CommonElementInput,
  },
  mixins: [apiMixin],
  data: function () {
    return {
      element: null,
      inited: false,
      loading: false,
      loadSpinnerVisible: false,
    };
  },
  methods: {
    keyUp: function (element) {
      element.error = null;
      if (element.timer) {
        clearTimeout(element.timer);
      }
      element.timer = setTimeout(() => {
        this.validate(element);
      }, 700);
    },
    validate: function (element) {
      if (element.require) {
        element.enable = true;
      }
      if (element.name === "name") {
        return this.validateName(element);
      }
      if (element.name === "servingWeight") {
        return this.validateServingWeight(element);
      }
      if (element.name === "recordDatetime") {
        return this.validateDatetime(element);
      }
      return true;
    },
    validateName: function (element) {
      if (!element.value) {
        element.error = "Необходимо ввести название еды";
        return false;
      }
      if (element.value.length > 200) {
        element.error = "Максимальная длина названия еды 200 символов";
        return false;
      }
      element.error = null;
      return true;
    },
    validateServingWeight: function (element) {
      element.error = null;
      if (!element.value) {
        element.error = "Необходимо ввести вес порции";
        return false;
      }
      let regexp = /^\d+((.|,)\d+)?$/;
      var value = parseFloat(element.value);
      if (!isFinite(value) || !regexp.test(element.value)) {
        element.error = "Поле может содержать только положительное число";
        return false;
      }
      return true;
    },
    validateDatetime: function (element) {
      element.error = null;
      if (!element.value) {
        element.error = "Необходимо ввести дату и время";
        return false;
      }
      let regexp =
        /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (\d|0\d|1\d|2[0-3]):[0-5]\d$/;
      if (!regexp.test(element.value)) {
        element.error = "Неверный формат даты и времени";
        return false;
      }
      return true;
    },
    onSubmit: function () {
      let isValid = true;
      let data = {};
      for (let groupKey in this.element) {
        for (let elementKey in this.element[groupKey]) {
          let element = this.element[groupKey][elementKey];
          isValid = this.validate(element) && isValid;
          if (element.enable) {
            data[element.name] = element.value;
          }
        }
      }
      if (!isValid) {
        return;
      }
      this.loading = true;
      data["source"] = this.$route.query.id;
      data["accessToken"] = localStorage.getItem("accessToken");
      this.apiPost("/api/journal-product-api/copy", data)
        .then((response) => {
          if (!response.data.success) {
            window.location.href = "/journal";
            return;
          }
          this.$router.push({
            path: "/journal/show",
            query: {
              id: response.data.data.id,
              date: response.data.data.date,
            },
          });
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.$router.push({
        path: "/journal/show",
        query: {
          id: this.$route.query.id,
        },
      });
    },
  },
  mounted: function () {
    if (!this.$route.query.id) {
      window.location.href = "/journal";
    }
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    let schemaInited = false;
    let productInited = false;
    let onInited = function (self, data) {
      if (self.element === null) {
        self.element = Object.assign({}, data);
      } else {
        for (let groupKey in data) {
          if (self.element[groupKey] !== undefined) {
            for (let elementKey in self.element[groupKey]) {
              self.element[groupKey][elementKey] = Object.assign(
                {},
                self.element[groupKey][elementKey],
                data[groupKey][elementKey],
              );
            }
          } else {
            self.element[groupKey] = Object.assign({}, data[groupKey]);
          }
        }
      }
      if (schemaInited && productInited) {
        self.inited = true;
        clearTimeout(loadSpinnerTimer);
      }
    };
    this.apiGet("/api/journal-product-api/copy-form-schema")
      .then((response) => {
        if (response.data.success !== true) {
          window.location.href = "/journal";
          return;
        }
        schemaInited = true;
        onInited(this, response.data.data);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
    this.apiGet("/api/journal-product-api/copy-form-data", {
      params: {
        id: this.$route.query.id,
      },
    })
      .then((response) => {
        if (response.data.success !== true) {
          window.location.href = "/journal";
          return;
        }
        for (let groupKey in response.data.data) {
          for (let elementKey in response.data.data[groupKey]) {
            response.data.data[groupKey][elementKey] = {
              enable: true,
              value: response.data.data[groupKey][elementKey],
            };
          }
        }
        productInited = true;
        onInited(this, response.data.data);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
