<template>
  <div>
    <div>
      <a
        @click="openClick"
        class="btn mb-2 p-2 list-group-item-action text-reset border-0"
      >
        <div class="media">
          <div class="align-self-center mr-2" v-if="isChartVisible">
            <DoughnutChart
              :energyConsumedSum="energyConsumedSum"
              :energyConsumedLimit="energyConsumedLimit"
              :proteinSum="proteinSum"
              :proteinLimit="proteinLimit"
              :fatTotalSum="fatTotalSum"
              :fatTotalLimit="fatTotalLimit"
              :carbohydratesSum="carbohydratesSum"
              :carbohydratesLimit="carbohydratesLimit"
            />
          </div>
          <div class="media-body">
            <div class="mb-0 d-flex">
              <span class="d-flex align-items-center">
                <span
                  class="badge badge-pill badge-danger mr-1"
                  style="width: 1rem; height: 1rem"
                  v-if="isChartVisible"
                  >{{ " " }}</span
                >
                <span>Калорийность</span>
              </span>
              <b class="pl-1 ml-auto"> {{ energyConsumedSum }}</b>
            </div>
            <div class="mb-0 d-flex">
              <span class="d-flex align-items-center">
                <span
                  class="badge badge-pill badge-primary mr-1"
                  style="width: 1rem; height: 1rem"
                  v-if="isChartVisible"
                  >{{ " " }}</span
                >
                <span>Белки</span>
              </span>
              <b class="pl-1 ml-auto"> {{ proteinSum }}</b>
            </div>
            <div class="mb-0 d-flex">
              <span class="d-flex align-items-center">
                <span
                  class="badge badge-pill badge-warning mr-1"
                  style="width: 1rem; height: 1rem"
                  v-if="isChartVisible"
                  >{{ " " }}</span
                >
                <span>Жиры</span>
              </span>
              <b class="pl-1 ml-auto"> {{ fatTotalSum }}</b>
            </div>
            <div class="mb-0 d-flex">
              <span class="d-flex align-items-center">
                <span
                  class="badge badge-pill badge-success mr-1"
                  style="width: 1rem; height: 1rem"
                  v-if="isChartVisible"
                  >{{ " " }}</span
                >
                <span>Углеводы</span>
              </span>
              <b class="pl-1 ml-auto"> {{ carbohydratesSum }}</b>
            </div>
          </div>
        </div>
        <a href="#" @click="openClick">Открыть полный отчет</a>
      </a>
    </div>
  </div>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date.js";
import { onboardingMixin } from "@/mixins/onboarding.js";
import DoughnutChart from "@/components/common/Doughnut.vue";

export default {
  name: "JournalSummary",
  props: [
    "energyConsumedSum",
    "energyConsumedLimit",
    "proteinPercent",
    "fatTotalPercent",
    "carbohydratesPercent",
    "proteinSum",
    "proteinLimit",
    "fatTotalSum",
    "fatTotalLimit",
    "carbohydratesSum",
    "carbohydratesLimit",
  ],
  components: {
    DoughnutChart,
  },
  mixins: [dateMixin, productMixin, onboardingMixin],
  computed: {
    date: function () {
      return this.$store.state.date;
    },
    isChartVisible: function () {
      return (
        this.energyConsumedLimit > 0 &&
        this.proteinLimit > 0 &&
        this.fatTotalLimit > 0 &&
        this.carbohydratesLimit > 0
      );
    },
  },
  methods: {
    openClick: function () {
      this.deleteOnboardingId(3);
      this.$router.push({
        path: "/journal/report",
        query: {
          date: this.getDateString(this.date),
        },
      });
    },
  },
};
</script>
