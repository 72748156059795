import axios from "axios";
import Client from "@/api/Client";

export const apiMixin = {
  methods: {
    async apiPost(url, data) {
      return new Client().apiPost(url, data);
    },
    async apiGet(url, config = {}) {
      if (config.params === undefined) {
        config.params = {};
      }
      config.params.accessToken = await this.createAccessToken();
      const response = await axios.get(new Client().getUrl(url), config);
      if (response.data.error === "invalid_token") {
        localStorage.removeItem("accessToken");
        return this.apiGet(url, config);
      }
      return response;
    },
    async createAccessToken() {
      return new Client().createAccessToken({
        utmSource: this.$route.query.utm_source,
        utmMedium: this.$route.query.utm_medium,
        utmCampaign: this.$route.query.utm_campaign,
        utmContent: this.$route.query.utm_content,
        utmTerm: this.$route.query.utm_term,
      });
    },
  },
};
