<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

import { Doughnut } from "vue-chartjs";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 120,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    energyConsumedSum: {},
    energyConsumedLimit: {},
    proteinSum: {},
    proteinLimit: {},
    fatTotalSum: {},
    fatTotalLimit: {},
    carbohydratesSum: {},
    carbohydratesLimit: {},
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: ["#dc3545", "#e9ecef"],
            data: [
              this.energyConsumedSum,
              Math.max(0, this.energyConsumedLimit - this.energyConsumedSum),
            ],
          },
          {
            backgroundColor: ["#007bff", "#e9ecef"],
            data: [
              this.proteinSum,
              Math.max(0, this.proteinLimit - this.proteinSum),
            ],
          },
          {
            backgroundColor: ["#ffc107", "#e9ecef"],
            data: [
              this.fatTotalSum,
              Math.max(0, this.fatTotalLimit - this.fatTotalSum),
            ],
          },
          {
            backgroundColor: ["#28a745", "#e9ecef"],
            data: [
              this.carbohydratesSum,
              Math.max(0, this.carbohydratesLimit - this.carbohydratesSum),
            ],
          },
        ],
      },
      chartOptions: {
        cutout: "20%",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
