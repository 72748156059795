<template>
  <div class="form-group mb-3">
    <label v-bind:for="element.name" v-if="element.require">
      {{ element.label }}
    </label>
    <div class="custom-control custom-checkbox" v-else>
      <input
        v-if="!element.require"
        type="checkbox"
        class="custom-control-input"
        v-bind:id="element.name"
        v-model="element.enable"
        v-bind:disabled="loadingFlag"
      />
      <label class="custom-control-label" v-bind:for="element.name">
        {{ element.label }}
      </label>
    </div>
    <div
      v-if="element.enable || element.require"
      class="input-group"
      v-bind:class="{ 'mt-2': !element.require }"
    >
      <div class="input-group-prepend" v-if="element.type === 'time'">
        <button
          class="btn btn-outline-secondary"
          type="button"
          v-on:click="decTime"
          v-bind:disabled="loadingFlag"
        >
          -
        </button>
      </div>
      <input
        class="form-control"
        v-bind:class="{ 'is-invalid': element.error }"
        v-model="element.value"
        v-bind:disabled="loadingFlag"
        v-on:keyup="onKeyUp(element)"
      />
      <div class="input-group-append" v-if="element.unit">
        <span class="input-group-text">
          {{ element.unit }}
        </span>
      </div>
      <div class="input-group-append" v-if="element.type === 'time'">
        <button
          class="btn btn-outline-secondary"
          type="button"
          v-on:click="incTime"
          v-bind:disabled="loadingFlag"
        >
          +
        </button>
      </div>
      <div class="invalid-feedback" v-if="element.error">
        {{ element.error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonElementInput",
  props: ["element", "loadingFlag", "onKeyUp"],
  methods: {
    decTime: function () {
      let regexp = /^(\d|0\d|1\d|2[0-3]):([0-5]\d)$/;
      let result = regexp.exec(this.element.value);
      if (result === null) {
        this.element.value = "0:00";
        return;
      }
      let hours = result[1];
      let minutes = result[2];
      if (minutes == 0) {
        if (--hours < 0) {
          return;
        } else {
          this.element.value = hours + ":30";
          return;
        }
      } else if (minutes > 30) {
        this.element.value = hours + ":30";
        return;
      }
      this.element.value = hours + ":00";
    },
    incTime: function () {
      let regexp = /^(\d|0\d|1\d|2[0-3]):([0-5]\d)$/;
      let result = regexp.exec(this.element.value);
      if (result === null) {
        this.element.value = "23:30";
        return;
      }
      let hours = result[1];
      let minutes = result[2];
      if (minutes < 30) {
        this.element.value = hours + ":30";
        return;
      } else if (++hours > 23) {
        return;
      }
      this.element.value = hours + ":00";
    },
    pad: function (number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },
  },
  mounted: function () {
    let defaultElement = {
      type: null,
      name: null,
      label: null,
      unit: null,
      require: null,
      enable: null,
      value: null,
      timer: null,
      error: null,
    };
    for (let property in defaultElement) {
      if (this.element[property] === undefined) {
        this.element[property] = defaultElement[property];
      }
    }
    if (
      this.element.type === "time" &&
      (this.element.value === null || this.element.value === "")
    ) {
      if (typeof this.$store.state.journalFoodLastTime === "string") {
        this.element.value = this.$store.state.journalFoodLastTime;
      } else {
        let now = new Date();

        this.element.value = now.getHours() + ":" + this.pad(now.getMinutes());
      }
    }
  },
};
</script>
