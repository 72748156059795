<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
              v-bind:disabled="loading"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Шаг 6/{{ stepCount }}: Часовой пояс</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-2">
        <img src="@/assets/image/start/6.jpg" width="107px" height="107px" />
      </div>
      <Transition name="fade">
        <div key="if" v-if="inited">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <div class="input-group">
                <select
                  v-model="selected"
                  class="custom-select"
                  v-bind:disabled="loading"
                >
                  <option
                    v-for="(value, name) in timeZones"
                    v-bind:value="name"
                    v-bind:key="name"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group mt-3">
              <button
                v-bind:disabled="loading"
                type="submit"
                class="btn btn-lg btn-primary btn-block"
              >
                <span
                  v-if="loading"
                  class="spinner-border"
                  style="width: 1.25rem; height: 1.25rem"
                ></span>
                <span v-else>Далее</span>
              </button>
            </div>
          </form>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { dateMixin } from "@/mixins/date";
import { startMixin } from "@/mixins/start";
import { apiMixin } from "@/mixins/api";

export default {
  name: "StartTimeZone",
  data: function () {
    return {
      inited: false,
      loadSpinnerVisible: false,
      selected: null,
      timeZones: null,
      loading: false,
    };
  },
  mixins: [dateMixin, startMixin, apiMixin],
  methods: {
    onSubmit: function () {
      this.loading = true;
      this.apiPost("/api/time-zone-api/save", {
        time_zone_id: this.selected,
      })
        .then((response) => {
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          this.routerPushWithCurrentQuery("/start/email");
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.routerPushWithCurrentQuery("/start/elements-setting");
    },
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    this.apiGet("/api/time-zone-api/load")
      .then((response) => {
        if (response.data.status != "ok") {
          window.location.href = "/journal";
          return;
        }
        this.selected = response.data.selected;
        this.timeZones = response.data.items;
        this.inited = true;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
