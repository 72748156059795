import StartGoal from "../views/start/1_Goal.vue";
import StartGender from "../views/start/2_Gender.vue";
import StartActivity from "../views/start/3_Activity.vue";
import StartBody from "../views/start/4_Body.vue";
import StartTimeZone from "../views/start/6_TimeZone.vue";
import StartEmail from "../views/start/7_Email.vue";
import StartElementsSetting from "../views/start/5_ElementsSetting.vue";
import AccountCabinet from "../views/account/Cabinet.vue";
import AccountSignUp from "../views/account/SignUp.vue";
import AccountTimeZone from "../views/account/TimeZone.vue";
import AccountElementsSetting from "../views/account/ElementsSetting.vue";
import AccountNutritionProgram from "../views/account/NutritionProgram.vue";
import AccountSupport from "../views/account/Support.vue";
import AccountPayment from "../views/account/Payment.vue";
import LegalConfidential from "../views/legal/Confidential.vue";
import JournalIndex from "../views/journal/Index.vue";
import JournalCalendar from "../views/journal/Calendar.vue";
import JournalShow from "../views/journal/Show.vue";
import JournalDelete from "../views/journal/Delete.vue";
import JournalReport from "../views/journal/Report.vue";
import JournalEdit from "../views/journal/Edit.vue";
import JournalCopy from "../views/journal/Copy.vue";
import ProductSelect from "../views/product/Select.vue";
import ProductAdd from "../views/product/Add.vue";
import ProductNew from "../views/product/New.vue";
import OfflineIndex from "../views/offline/Index.vue";
import ClickTrack from "../views/click/Track.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/offline",
    component: OfflineIndex,
  },
  {
    path: "/start/goal",
    component: StartGoal,
  },
  {
    path: "/start/gender",
    component: StartGender,
  },
  {
    path: "/start/activity",
    component: StartActivity,
  },
  {
    path: "/start/body",
    component: StartBody,
  },
  {
    path: "/start/time-zone",
    component: StartTimeZone,
  },
  {
    path: "/start/email",
    component: StartEmail,
  },
  {
    path: "/start/elements-setting",
    component: StartElementsSetting,
  },
  {
    path: "/account/cabinet",
    component: AccountCabinet,
  },
  {
    path: "/account/sign-up",
    component: AccountSignUp,
  },
  {
    path: "/account/change-time-zone",
    component: AccountTimeZone,
  },
  {
    path: "/account/elements-setting",
    component: AccountElementsSetting,
  },
  {
    path: "/account/nutrition-program",
    component: AccountNutritionProgram,
  },
  {
    path: "/account/support",
    component: AccountSupport,
  },
  {
    path: "/account/payment",
    component: AccountPayment,
  },
  {
    path: "/legal/confidential",
    component: LegalConfidential,
  },
  {
    path: "/journal",
    component: JournalIndex,
  },
  {
    path: "/journal/calendar",
    component: JournalCalendar,
  },
  {
    path: "/journal/show",
    component: JournalShow,
  },
  {
    path: "/journal/delete",
    component: JournalDelete,
  },
  {
    path: "/journal/report",
    component: JournalReport,
  },
  {
    path: "/journal/edit",
    component: JournalEdit,
  },
  {
    path: "/journal/copy",
    component: JournalCopy,
  },
  {
    path: "/product/select",
    component: ProductSelect,
  },
  {
    path: "/product/add" /* delete after yandex, google update */,
    component: ProductAdd,
  },
  {
    path: "/product/new",
    component: ProductNew,
  },
  {
    path: "/product/:id/:friendlyUrl?",
    component: ProductAdd,
  },
  {
    path: "/click/track",
    component: ClickTrack,
  },
  {
    path: "/",
    redirect: "/journal",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/journal",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.auth) {
    localStorage.setItem("accessToken", to.query.auth);
    const query = { ...to.query };
    delete query.auth;
    next({ path: to.path, query, replace: true });
  }
  next();
});

router.afterEach((to) => {
  setTimeout(() => {
    // eslint-disable-next-line no-undef
    ym(51054236, "hit", to);
  });
});

export default router;
