<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Настройки</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div key="if" v-if="inited">
          <div class="list-group mb-2">
            <router-link
              to="/account/elements-setting"
              class="list-group-item list-group-item-action"
              >Настроить суточные нормы
            </router-link>
            <router-link
              to="/account/change-time-zone"
              class="list-group-item list-group-item-action"
              >Сменить часовой пояс
            </router-link>
          </div>
          <div class="list-group mb-2">
            <router-link
              to="/account/support"
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            >
              Служба поддержки
              <span
                class="badge badge-danger badge-pill"
                v-if="unreadMessageCount > 0"
                >{{ unreadMessageCount }}</span
              >
            </router-link>
          </div>
          <div class="list-group">
            <div v-if="created">
              <button
                v-bind:disabled="signOutLoading"
                v-on:click="signOut"
                class="list-group-item list-group-item-action"
              >
                <span
                  v-if="signOutLoading"
                  class="spinner-border"
                  style="width: 1rem; height: 1rem"
                ></span>
                <span v-else>Выйти</span>
              </button>
            </div>
            <div v-else>
              <router-link
                to="/account/sign-up"
                class="list-group-item list-group-item-action"
                >Войти</router-link
              >
            </div>
          </div>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "AccountCabinet",
  mixins: [apiMixin],
  data: function () {
    return {
      inited: false,
      created: null,
      signOutLoading: null,
      unreadMessageCount: 0,
      loadSpinnerVisible: false,
    };
  },
  methods: {
    signOut: function () {
      this.signOutLoading = true;
      localStorage.removeItem("accessToken");
      this.$router.push("/journal");
    },
    back: function () {
      this.$router.push("/journal");
    },
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    this.apiGet("/api/account-api/check")
      .then((response) => {
        if (response.data.status != "ok") {
          window.location.href = "/journal";
          return;
        }
        this.created = response.data.created;
        this.unreadMessageCount = response.data.unreadMessageCount;
        this.inited = true;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
