<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
              v-bind:disabled="loading"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Шаг 4/{{ stepCount }}: Телосложение</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-2">
        <img src="@/assets/image/start/4.jpg" width="107px" height="107px" />
      </div>
      <form v-on:submit.prevent="onSubmit">
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': age.error }"
              v-model.trim="age.value"
              placeholder="Возраст"
              inputmode="decimal"
              v-on:keyup="keyUpAge"
              name="age"
              v-bind:disabled="loading"
            />
            <div class="input-group-append">
              <span class="input-group-text">лет</span>
            </div>
            <div class="invalid-feedback" v-if="age.error">{{ age.error }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': height.error }"
              v-model.trim="height.value"
              placeholder="Рост"
              inputmode="decimal"
              v-on:keyup="keyUpHeight"
              name="height"
              v-bind:disabled="loading"
            />
            <div class="input-group-append">
              <span class="input-group-text">см</span>
            </div>
            <div class="invalid-feedback" v-if="height.error">
              {{ height.error }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': weight.error }"
              v-model.trim="weight.value"
              placeholder="Вес"
              inputmode="decimal"
              v-on:keyup="keyUpWeight"
              name="weight"
              v-bind:disabled="loading"
            />
            <div class="input-group-append">
              <span class="input-group-text">кг</span>
            </div>
            <div class="invalid-feedback" v-if="weight.error">
              {{ weight.error }}
            </div>
          </div>
        </div>
        <div class="form-group mt-3">
          <button
            v-bind:disabled="loading"
            type="submit"
            class="btn btn-lg btn-primary btn-block"
          >
            <span
              v-if="loading"
              class="spinner-border"
              style="width: 1.25rem; height: 1.25rem"
            ></span>
            <span v-else>Далее</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { startMixin } from "@/mixins/start";
import { apiMixin } from "@/mixins/api";

export default {
  name: "StartBody",
  mixins: [startMixin, apiMixin],
  data: function () {
    return {
      age: {
        value: null,
        timer: null,
        error: null,
      },
      height: {
        value: null,
        timer: null,
        error: null,
      },
      weight: {
        value: null,
        timer: null,
        error: null,
      },
      loading: false,
    };
  },
  methods: {
    keyUpAge: function () {
      this.age.error = null;
      clearTimeout(this.age.timer);
      this.age.timer = setTimeout(() => {
        this.validateAge();
      }, 700);
    },
    keyUpHeight: function () {
      this.height.error = null;
      clearTimeout(this.height.timer);
      this.height.timer = setTimeout(() => {
        this.validateHeight();
      }, 700);
    },
    keyUpWeight: function () {
      this.weight.error = null;
      clearTimeout(this.weight.timer);
      this.weight.timer = setTimeout(() => {
        this.validateWeight();
      }, 700);
    },
    validateAge: function () {
      if (!this.age.value) {
        this.age.error = "Необходимо ввести возраст";
        return false;
      }
      let regexp = /^[0-9]*$/;
      var age = parseInt(this.age.value);
      if (!isFinite(age) || !regexp.test(this.age.value)) {
        this.age.error = "Неверный возраст";
        return false;
      }
      if (age < 1 || age > 140) {
        this.age.error = "Неверный возраст";
        return false;
      }
      this.age.error = null;
      return true;
    },
    validateHeight: function () {
      if (!this.height.value) {
        this.height.error = "Необходимо ввести рост";
        return false;
      }
      let regexp = /^[0-9]*(.|,)?[0-9]+$/;
      var height = parseFloat(this.height.value);
      if (!isFinite(height) || !regexp.test(this.height.value)) {
        this.height.error = "Неверный рост";
        return false;
      }
      if (height < 50 || height > 300) {
        this.height.error = "Неверный рост";
        return false;
      }
      this.height.error = null;
      return true;
    },
    validateWeight: function () {
      if (!this.weight.value) {
        this.weight.error = "Необходимо ввести вес";
        return false;
      }
      let regexp = /^[0-9]*(.|,)?[0-9]+$/;
      var weight = parseFloat(this.weight.value);
      if (!isFinite(weight) || !regexp.test(this.weight.value)) {
        this.weight.error = "Неверный вес";
        return false;
      }
      if (weight < 10 || weight > 700) {
        this.weight.error = "Неверный вес";
        return false;
      }
      this.weight.error = null;
      return true;
    },
    onSubmit: function () {
      let isValidAge = this.validateAge();
      let isValidHeight = this.validateHeight();
      let isValidWeight = this.validateWeight();
      if (!isValidAge || !isValidHeight || !isValidWeight) {
        return;
      }
      this.loading = true;
      this.apiPost(
        "/api/calculator-api/calculate",
        Object.assign(
          {
            age: this.age.value,
            height: this.height.value,
            weight: this.weight.value,
          },
          this.$route.query,
        ),
      )
        .then((response) => {
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          this.routerPushWithCurrentQuery("/start/elements-setting", {
            age: this.age.value,
            height: this.height.value,
            weight: this.weight.value,
          });
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.routerPushWithCurrentQuery("/start/activity");
    },
  },
  mounted: function () {
    this.age.value = this.$route.query.age;
    this.height.value = this.$route.query.height;
    this.weight.value = this.$route.query.weight;
  },
};
</script>
