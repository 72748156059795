import axios from "axios";
export default class Client {
    async calendarGetFilled() {
        const response = await axios.post(this.getUrl("/api/api"), {
            jsonrpc: "2.0",
            id: 1,
            method: "calendar.getFilled",
            params: { authKey: await this.createAccessToken({}) },
        });
        if (response.data.result !== undefined) {
            return response.data.result.filled;
        }
        else {
            throw new Error("Invalid response");
        }
    }
    getFoodsFromResponse(response) {
        return response.data.foods;
    }
    async apiPost(url, data) {
        data.accessToken = await this.createAccessToken({});
        const response = await axios.post(this.getUrl(url), data);
        if (response.data.error === "invalid_token") {
            localStorage.removeItem("accessToken");
            return this.apiPost(url, data);
        }
        return response;
    }
    async createAccessToken(data) {
        return navigator.locks.request("api_token", async () => {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken === null) {
                const createUserResponse = await axios.post(this.getUrl("/api/user-api/create"), data);
                localStorage.setItem("accessToken", createUserResponse.data.accessToken);
                return createUserResponse.data.accessToken;
            }
            return accessToken;
        });
    }
    getUrl(path) {
        return "https://api.feetab.com" + path;
    }
}
