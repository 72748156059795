<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="previousMonth"
            >
              <img
                src="@/assets/image/icon/chevrons-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>{{ monthLabel }} {{ year }}</b>
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="nextMonth"
            >
              <img
                src="@/assets/image/icon/chevrons-right.svg"
                width="24px"
                height="24px"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between mb-3 mt-1"
        v-for="(week, index) in weeks"
        v-bind:key="index"
      >
        <div v-for="(day, index) in week" v-bind:key="index">
          <button
            class="d-flex justify-content-center align-items-center rounded-circle btn"
            style="width: 40px; height: 40px"
            v-bind:class="{
              'btn-primary': day.toDateString() === date.toDateString(),
              'text-primary':
                day.toDateString() !== date.toDateString() &&
                day.toDateString() === new Date().toDateString(),
              'btn-outline-success':
                filled !== null &&
                day.toDateString() !== date.toDateString() &&
                day.toDateString() !== new Date().toDateString() &&
                filled.includes(getDateString(day)),
            }"
            v-on:click="setDate(day)"
            v-if="day.getMonth() == month"
          >
            {{ day.getDate() }}
          </button>
          <div style="width: 40px; height: 40px" v-else></div>
        </div>
      </div>
      <button
        class="btn btn-block btn-primary rounded-pill"
        v-on:click="setDate(new Date())"
      >
        Сегодня
      </button>
    </div>
  </div>
</template>

<script>
import { dateMixin } from "@/mixins/date";
import Client from "@/api/Client";

export default {
  name: "JournalCalendar",
  mixins: [dateMixin],
  data: function () {
    return {
      date: null,
      month: null,
      year: null,
      filled: null,
    };
  },
  computed: {
    monthLabel: function () {
      return this.$store.state.months[this.month];
    },
    weeks: function () {
      return this.getWeeks();
    },
  },
  methods: {
    setDate: function (date) {
      this.$router.push({
        path: "/journal",
        query: {
          date: this.getDateString(date),
        },
      });
    },
    nextMonth: function () {
      let date = new Date(this.year, this.month + 1, 1);
      this.month = date.getMonth();
      this.year = date.getFullYear();
    },
    previousMonth: function () {
      let date = new Date(this.year, this.month - 1, 1);
      this.month = date.getMonth();
      this.year = date.getFullYear();
    },
    getWeeks: function () {
      let weeks = [];
      let currentDay = new Date(this.year, this.month, 1);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        let week = this.getWeek(currentDay);
        if (
          week[0].getMonth() !== this.month &&
          week[6].getMonth() !== this.month
        ) {
          break;
        }
        weeks.push(week);
        currentDay = new Date(currentDay.getTime() + 7 * 24 * 60 * 60 * 1000);
      }
      return weeks;
    },
  },
  mounted: async function () {
    let date = new Date(this.$route.query.date);
    this.date = date;
    this.month = date.getMonth();
    this.year = date.getFullYear();
    this.filled = await new Client().calendarGetFilled();
  },
};
</script>
