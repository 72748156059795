<template>
  <div id="nutritionProgram">
    <button
      v-on:click="buttonOrderNutritionProgramClicked"
      class="mb-3 btn btn-block btn-primary"
    >
      Заказать программу питания
    </button>
  </div>
</template>

<script>
export default {
  name: "JournalNutritionProgram",
  methods: {
    buttonOrderNutritionProgramClicked: function () {
      this.$router.push({
        path: "/account/nutrition-program",
      });
    },
  },
};
</script>
