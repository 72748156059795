<template>
  <!-- Yandex.RTB R-A-11243531-1 -->
  <div id="yandex_rtb_R-A-11243531-1"></div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(function () {
  window.yaContextCb.push(() => {
    //eslint-disable-next-line no-undef
    Ya.Context.AdvManager.render({
      blockId: "R-A-11243531-1",
      renderTo: "yandex_rtb_R-A-11243531-1",
    });
  });
});
</script>
