export const dateMixin = {
  methods: {
    getWeek(date) {
      const week = [];
      const dayOfWeek = this.getDayOfWeek(date);
      for (let i = 0; i <= 6; i++) {
        week[i] = new Date(
          date.getTime() + 24 * 60 * 60 * 1000 * (i - dayOfWeek),
        );
      }
      return week;
    },
    getDayOfWeek(date) {
      let dayOfWeek = date.getDay();
      dayOfWeek--;
      if (dayOfWeek === -1) {
        dayOfWeek = 6;
      }
      return dayOfWeek;
    },
    getDateString(date) {
      function pad(number) {
        if (number < 10) {
          return `0${number}`;
        }
        return number;
      }

      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    },
  },
};
