<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
              v-bind:disabled="loading"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Вход в аккаунт</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div key="if" v-if="sended">
        <div class="p-2 mb-3">
          Код подтверждения был отправлен на <b>{{ email.value }}</b
          >. <a href="#" v-on:click="sended = false">Изменить</a>
        </div>
        <form v-on:submit.prevent="onSubmitCode">
          <div class="form-group">
            <div class="input-group">
              <input
                class="form-control"
                v-bind:class="{ 'is-invalid': code.error }"
                v-model.trim="code.value"
                placeholder="Код подтверждения"
                v-on:keyup="keyUpCode"
                v-bind:disabled="loading"
              />
              <div class="invalid-feedback" v-if="code.error">
                {{ code.error }}
              </div>
            </div>
          </div>
          <div class="form-group mt-3 mb-3">
            <button
              v-bind:disabled="loading"
              type="submit"
              class="btn btn-lg btn-primary btn-block"
            >
              <span
                v-if="loading"
                class="spinner-border"
                style="width: 1.25rem; height: 1.25rem"
              ></span>
              <span v-else>Войти</span>
            </button>
          </div>
        </form>
      </div>
      <div key="else" v-else>
        <div class="p-2 mb-3">
          Введите Ваш адрес электронной почты. Продолжая, Вы соглашаетесь с
          <router-link
            :to="{
              path: '/legal/confidential',
              query: { source: 'account/sign-up' },
            }"
            >Политикой конфиденциальности</router-link
          >.
        </div>
        <form v-on:submit.prevent="onSubmit">
          <div class="form-group">
            <div class="input-group">
              <input
                class="form-control"
                v-bind:class="{ 'is-invalid': email.error }"
                v-model.trim="email.value"
                placeholder="Адрес электронной почты"
                v-on:keyup="keyUpEmail"
                v-bind:disabled="loading"
              />
              <div class="invalid-feedback" v-if="email.error">
                {{ email.error }}
              </div>
            </div>
          </div>
          <div class="form-group mt-3 mb-3">
            <button
              v-bind:disabled="loading"
              type="submit"
              class="btn btn-lg btn-primary btn-block"
            >
              <span
                v-if="loading"
                class="spinner-border"
                style="width: 1.25rem; height: 1.25rem"
              ></span>
              <span v-else>Далее</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "AccountSignUp",
  mixins: [apiMixin],
  data: function () {
    return {
      email: {
        value: null,
        timer: null,
        error: null,
      },
      code: {
        value: null,
        timer: null,
        error: null,
      },
      sended: false,
      loading: false,
    };
  },
  methods: {
    keyUpEmail: function () {
      this.email.error = null;
      clearTimeout(this.email.timer);
      this.email.timer = setTimeout(() => {
        this.validateEmail();
      }, 700);
    },
    keyUpCode: function () {
      this.code.error = null;
      clearTimeout(this.code.timer);
      this.code.timer = setTimeout(() => {
        this.validateCode();
      }, 700);
    },
    validateEmail: function () {
      if (!this.email.value) {
        this.email.error = "Необходимо ввести адрес электронной почты";
        return false;
      }
      let regexp = /^.+@.+$/;
      if (!regexp.test(this.email.value)) {
        this.email.error = "Неверный формат адреса электронной почты";
        return false;
      }
      this.email.error = null;
      return true;
    },
    validateCode: function () {
      if (!this.code.value) {
        this.code.error = "Необходимо ввести код подтверждения";
        return false;
      }
      this.code.error = null;
      return true;
    },
    onSubmit: function () {
      if (!this.validateEmail()) {
        return;
      }
      this.loading = true;
      this.sended = false;
      this.apiPost("/api/auth-api/reset", {
        email: this.email.value,
      })
        .then((response) => {
          if (response.data.error) {
            if (response.data.error === 1) {
              this.email.error = "Неверный формат адреса электронной почты";
              this.loading = false;
              return;
            }
            if (response.data.error === 3) {
              this.email.error = "Сервис временно недоступен, попробуйте позже";
              this.loading = false;
              return;
            }
            window.location.href = "/journal";
            return;
          }
          this.email.value = response.data.email;
          this.sended = true;
          this.loading = false;
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    onSubmitCode: function () {
      if (!this.validateCode()) {
        return;
      }
      this.loading = true;
      this.apiPost("/api/auth-api/verify", {
        email: this.email.value,
        code: this.code.value,
      })
        .then((response) => {
          if (response.data.error) {
            if (response.data.error === 3) {
              this.code.error = "Неверный код подтверждения";
              this.loading = false;
              return;
            }
            this.code.value = null;
            this.code.error = null;
            this.sended = false;
            this.loading = false;
            return;
          }
          if (response.data.accessToken !== null) {
            localStorage.setItem("accessToken", response.data.accessToken);
          }
          if (this.$route.query.from == "journal") {
            window.location.href = "/journal";
            return;
          }
          if (this.$route.query.from == "pay") {
            window.location.href = "/account/pay";
            return;
          }
          this.$router.push({
            path: "/account/cabinet",
          });
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      if (this.$route.query.from === "journal") {
        this.$router.push({
          path: "/journal",
        });
        return;
      }
      if (this.$route.query.from === "pay") {
        window.location.href = "/journal";
        return;
      }
      this.$router.push({
        path: "/account/cabinet",
      });
    },
  },
};
</script>
