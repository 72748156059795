export const startMixin = {
  data() {
    return {
      stepCount: 7,
    };
  },
  methods: {
    routerPushWithCurrentQuery(path, additionalQuery = {}) {
      const current = this.$route.query;
      this.$router.push({
        path,
        query: Object.assign(current, additionalQuery),
      });
    },
  },
};
