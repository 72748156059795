export const productMixin = {
  methods: {
    getEnergyConsumed(energyConsumed) {
      if (energyConsumed) {
        return energyConsumed;
      }
      return 0;
    },
    getProteinEnergy(value) {
      if (!value) {
        value = 0;
      }
      return 4 * value;
    },
    getFatTotalEnergy(value) {
      if (!value) {
        value = 0;
      }
      return 9 * value;
    },
    getCarbohydratesEnergy(value) {
      if (!value) {
        value = 0;
      }
      return 4 * value;
    },
  },
};
