<template>
  <div>
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Запись еды</b>
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="toNew"
            >
              <img
                src="@/assets/image/icon/plus.svg"
                width="24px"
                height="24px"
              />
            </button>
          </div>
        </div>
      </div>
      <div v-if="isFirst" class="pr-2 pl-2">
        <div class="p-2">
          Отлично! 😉 Пора сделать первую запись. Может быть
          <a href="#" v-on:click="searchApple">яблоко</a>?
        </div>
      </div>
      <form v-on:submit.prevent="onSubmit" class="mb-3 pl-2 pr-2 pt-2">
        <div class="form-group">
          <div class="input-group">
            <input
              :key="search"
              class="form-control form-control-lg"
              type="search"
              v-model="query.current"
              placeholder="Что записать?"
              v-on:keyup="keyUpQuery"
              autofocus
            />
          </div>
        </div>
      </form>
      <div v-if="query.current == query.loaded">
        <div v-if="items.length > 0">
          <div
            v-if="isFirst || $store.state.onboardingIdList.includes(1)"
            class="pr-2 pl-2"
          >
            <div class="alert alert-primary alert-dismissible fade show mb-2">
              Для записи нажмите на нужный продукт.
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                v-on:click="deleteOnboardingId(1)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div v-for="(item, index) in items" v-bind:key="index">
            <product-product
              v-bind:key="index"
              v-bind:product="item"
              v-bind:query="query.current"
            ></product-product>
          </div>
          <div v-if="hasMore" style="height: 3rem" class="pl-2 pr-2">
            <div v-if="page.current == page.loaded">
              <button
                class="btn btn-primary btn-block rounded-pill btn-lg"
                v-on:click="loadMore"
              >
                Загрузить еще
              </button>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Загрузка...</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="query.loaded !== ''" class="pr-2 pl-2">
          <div class="p-2 mb-3">
            Результатов по запросу «{{ query.loaded }}» не найдено. Повторите
            поиск или создайте <a href="#" v-on:click="toNew">новый продукт</a>.
          </div>
        </div>
        <div v-else class="pr-2 pl-2">
          <div class="p-2 mb-3">
            Вы можете выполнить поиск или создать
            <a href="#" v-on:click="toNew">новый продукт</a>.
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
          <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductProduct from "@/components/product/Product";
import { dateMixin } from "@/mixins/date";
import { onboardingMixin } from "@/mixins/onboarding.js";
import { apiMixin } from "@/mixins/api";

export default {
  name: "ProductSelect",
  components: {
    ProductProduct,
  },
  mixins: [dateMixin, onboardingMixin, apiMixin],
  data: function () {
    return {
      query: {
        current: "",
        requested: "",
        loaded: null,
      },
      page: {
        current: "",
        requested: "",
        loaded: "",
      },
      requestTimer: null,
      items: [],
      hasMore: false,
      loadSpinnerVisible: false,
    };
  },
  computed: {
    date: function () {
      return this.$store.state.date;
    },
    isFirst: function () {
      return this.$route.query.first === "1";
    },
  },
  methods: {
    searchApple: function () {
      this.query.current = "яблоко";
      this.onSubmit();
    },
    keyUpQuery: function () {
      clearTimeout(this.requestTimer);
      this.requestTimer = setTimeout(() => {
        this.page.current = 1;
        this.search(this.query.current);
      }, 300);
    },
    onSubmit: function () {
      clearTimeout(this.requestTimer);
      this.page.current = 1;
      this.search(this.query.current);
    },
    loadMore: function () {
      this.page.current = this.page.loaded + 1;
      this.search(this.query.current, this.page.current);
    },
    search: function (query, page = 1) {
      if (query == this.query.requested && page == this.page.requested) {
        return;
      }
      if (page == 1) {
        this.items = [];
      }
      this.query.requested = query;
      this.page.requested = page;
      this.apiGet("/api/product-api/find", {
        params: {
          query: query,
          page: page,
        },
      })
        .then((response) => {
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          if (query != this.query.current || page != this.page.current) {
            return;
          }
          this.items = this.items.concat(response.data.items);
          this.hasMore = response.data.hasMore;
          this.query.loaded = query;
          this.page.loaded = page;
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.$router.push({
        path: "/journal",
        query: {
          date: this.getDateString(this.date),
          first: this.$route.query.first,
        },
      });
    },
    toNew: function () {
      this.$router.push({
        path: "/product/new",
        query: {
          date: this.getDateString(this.date),
          query: this.query.current,
        },
      });
    },
  },
  mounted: function () {
    let date = new Date(this.$route.query.date);
    if (!isNaN(date)) {
      this.$store.commit("changeDate", date);
    }
    let query = this.$route.query.query;
    if (typeof query === "string") {
      this.query.current = query;
      this.page.current = 1;
      this.search(query);
    }
    this.loadSpinnerVisible = false;
    setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    this.loadOnboardingIdList();
    this.onSubmit();
  },
};
</script>
