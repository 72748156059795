<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Отчёт</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div key="if" v-if="report !== null">
          <div v-for="(nutrient, index) in mainNutrientList" v-bind:key="index">
            <div class="p-2 mb-3">
              <div class="d-flex">
                <span>{{ nutrient.label }}</span>
                <b class="ml-auto pl-1"
                  >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
                >
              </div>
              <div v-if="nutrient.daily">
                <span class="text-muted"
                  >{{ nutrient.daily.label }} {{ nutrient.daily.value }}&nbsp;{{
                    nutrient.unitLabel
                  }}</span
                >
                <div class="progress mt-1" style="height: 7px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    v-bind:style="{ width: nutrient.daily.percent + '%' }"
                    v-bind:aria-valuenow="nutrient.daily.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="p-2 mb-3" v-if="nutrient.key === 'energyConsumed'">
              <div class="mb-0 d-flex">
                <span>БЖУ (% от ккал)</span
                ><b class="ml-auto pl-1"
                  >{{ proteinPercent }} / {{ fatTotalPercent }} /
                  {{ carbohydratesPercent }}</b
                >
              </div>
              <div class="progress mt-1" style="height: 7px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  v-bind:style="{ width: proteinPercent + '%' }"
                  v-bind:aria-valuenow="proteinPercent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  v-bind:style="{ width: fatTotalPercent + '%' }"
                  v-bind:aria-valuenow="fatTotalPercent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  v-bind:style="{ width: carbohydratesPercent + '%' }"
                  v-bind:aria-valuenow="carbohydratesPercent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="p-2 mb-3" v-if="vitaminNutrientList.length > 0">
            <b>Витамины</b>
          </div>
          <div
            v-for="(nutrient, index) in vitaminNutrientList"
            v-bind:key="index"
          >
            <div class="p-2 mb-3">
              <div class="d-flex">
                <span>{{ nutrient.label }}</span>
                <b class="ml-auto pl-1"
                  >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
                >
              </div>
              <div v-if="nutrient.daily">
                <span class="text-muted"
                  >{{ nutrient.daily.label }} {{ nutrient.daily.value }}&nbsp;{{
                    nutrient.unitLabel
                  }}</span
                >
                <div class="progress mt-1" style="height: 7px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    v-bind:style="{ width: nutrient.daily.percent + '%' }"
                    v-bind:aria-valuenow="nutrient.daily.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 mb-3" v-if="mineralNutrientList.length > 0">
            <b>Минералы</b>
          </div>
          <div
            v-for="(nutrient, index) in mineralNutrientList"
            v-bind:key="index"
          >
            <div class="p-2 mb-3">
              <div class="d-flex">
                <span>{{ nutrient.label }}</span>
                <b class="ml-auto pl-1"
                  >{{ nutrient.value }}&nbsp;{{ nutrient.unitLabel }}</b
                >
              </div>
              <div v-if="nutrient.daily">
                <span class="text-muted"
                  >{{ nutrient.daily.label }} {{ nutrient.daily.value }}&nbsp;{{
                    nutrient.unitLabel
                  }}</span
                >
                <div class="progress mt-1" style="height: 7px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    v-bind:style="{ width: nutrient.daily.percent + '%' }"
                    v-bind:aria-valuenow="nutrient.daily.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date";
import { apiMixin } from "@/mixins/api";

export default {
  name: "JournalReport",
  mixins: [productMixin, dateMixin, apiMixin],
  data: function () {
    return {
      loadSpinnerVisible: false,
      report: null,
    };
  },
  methods: {
    createNutrientList: function (object) {
      let list = [];
      for (let nutrientKey in object) {
        let nutrient = object[nutrientKey];
        let value = nutrient.value
          .toFixed(nutrient.precision)
          .replace(/^(\d+(\.[1-9]+)?)(.*)/, "$1");
        if (value === "0") {
          continue;
        }
        let listObject = {
          key: nutrientKey,
          label: nutrient.label,
          unitLabel: nutrient.unitLabel,
          value: value,
        };
        if (nutrient.daily !== undefined) {
          let dailyBalance = nutrient.daily - nutrient.value;
          let dailyBalanceLabel;
          let dailyBalancePercent;
          if (dailyBalance >= 0) {
            dailyBalanceLabel = "Осталось";
            dailyBalancePercent = Math.round(
              (nutrient.value / nutrient.daily) * 100,
            );
            if (dailyBalancePercent > 100) {
              dailyBalancePercent = 100;
            }
          } else {
            dailyBalanceLabel = "Превышение";
            dailyBalance = -1 * dailyBalance;
            dailyBalancePercent = 100;
          }
          dailyBalance = dailyBalance
            .toFixed(nutrient.precision)
            .replace(/^(\d+(\.[1-9]+)?)(.*)/, "$1");
          if (dailyBalance === "0") {
            continue;
          }
          listObject.daily = {
            label: dailyBalanceLabel,
            value: dailyBalance,
            percent: dailyBalancePercent,
          };
        }
        list.push(listObject);
      }
      return list;
    },
    back: function () {
      this.$router.push("/journal");
    },
  },
  computed: {
    energyConsumed: function () {
      let energyConsumed = 0;
      if (this.report.main.energyConsumed !== undefined) {
        energyConsumed = this.getEnergyConsumed(
          this.report.main.energyConsumed.value,
        );
      }
      return Math.round(energyConsumed);
    },
    proteinPercent: function () {
      if (this.energyConsumed === 0 || this.report.main.protein === undefined) {
        return 0;
      }
      return Math.round(
        (this.getProteinEnergy(this.report.main.protein.value) /
          this.energyConsumed) *
          100,
      );
    },
    fatTotalPercent: function () {
      if (
        this.energyConsumed === 0 ||
        !this.report.main ||
        !this.report.main.fatTotal
      ) {
        return 0;
      }
      return Math.round(
        (this.getFatTotalEnergy(this.report.main.fatTotal.value) /
          this.energyConsumed) *
          100,
      );
    },
    carbohydratesPercent: function () {
      if (
        this.energyConsumed === 0 ||
        !this.report.main ||
        !this.report.main.carbohydrates
      ) {
        return 0;
      }
      return Math.round(
        (this.getCarbohydratesEnergy(this.report.main.carbohydrates.value) /
          this.energyConsumed) *
          100,
      );
    },
    mainNutrientList: function () {
      return this.createNutrientList(this.report.main);
    },
    vitaminNutrientList: function () {
      return this.createNutrientList(this.report.vitamin);
    },
    mineralNutrientList: function () {
      return this.createNutrientList(this.report.mineral);
    },
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    let date = new Date(this.$route.query.date);
    if (isNaN(date)) {
      window.location.href = "/journal";
      return;
    }
    this.apiGet("/api/journal-api/report", {
      params: {
        date: this.getDateString(date),
      },
    })
      .then((response) => {
        if (response.data.result === undefined) {
          window.location.href = "/journal";
          return;
        }
        this.report = response.data.result;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
