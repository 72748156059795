<template>
  <div
    id="payment"
    v-if="daysBeforeExpiration !== null && daysBeforeExpiration <= 7"
  >
    <div v-if="timestamp === '00:00:00'" class="alert alert-danger mb-2">
      Срок действия доступа закончился.
    </div>
    <div v-else-if="daysBeforeExpiration >= 1" class="alert alert-warning mb-2">
      Срок действия доступа заканчивается через {{ daysBeforeExpiration }}
      {{ daysWordForm }}.
    </div>
    <div v-else class="alert alert-warning mb-2">
      Срок действия доступа заканчивается через {{ timestamp }}
    </div>
    <router-link
      to="/account/payment"
      class="mb-3 btn btn-block btn-primary btn-lg"
    >
      {{ paymentButtonLabel }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "JournalPayment",
  props: ["secondsBeforeExpiration", "paymentButtonLabel"],
  data: function () {
    return {
      currentSecondsBeforeExpiration: 0,
      startTimestamp: 0,
      timestamp: "",
      daysBeforeExpiration: null,
    };
  },
  mounted() {
    this.startTimestamp = Math.trunc(Date.now() / 1000);
    this.currentSecondsBeforeExpiration = this.secondsBeforeExpiration;
    this.getNow();
    setInterval(this.getNow, 1000);
  },
  computed: {
    daysWordForm: function () {
      return this.getWordForm(this.daysBeforeExpiration, "день", "дня", "дней");
    },
  },
  methods: {
    getNow: function () {
      this.currentSecondsBeforeExpiration =
        this.secondsBeforeExpiration -
        (Math.trunc(Date.now() / 1000) - this.startTimestamp);
      if (this.currentSecondsBeforeExpiration < 0) {
        this.currentSecondsBeforeExpiration = 0;
      }
      const minute = 60;
      const hour = 60 * minute;
      const day = 24 * hour;
      this.daysBeforeExpiration = Math.trunc(
        this.currentSecondsBeforeExpiration / day,
      );
      let hoursBeforeExpiration = Math.trunc(
        (this.currentSecondsBeforeExpiration -
          this.daysBeforeExpiration * day) /
          hour,
      );
      let minutesBeforeExpiration = Math.trunc(
        (this.currentSecondsBeforeExpiration -
          this.daysBeforeExpiration * day -
          hoursBeforeExpiration * hour) /
          minute,
      );
      let secondsBeforeExpiration =
        this.currentSecondsBeforeExpiration -
        this.daysBeforeExpiration * day -
        hoursBeforeExpiration * hour -
        minutesBeforeExpiration * minute;
      let days = this.label(this.daysBeforeExpiration);
      let hours = this.label(hoursBeforeExpiration);
      let minutes = this.label(minutesBeforeExpiration);
      let seconds = this.label(secondsBeforeExpiration);
      if (this.daysBeforeExpiration === 0) {
        this.timestamp = hours + ":" + minutes + ":" + seconds;
      } else {
        this.timestamp = days + ":" + hours + ":" + minutes + ":" + seconds;
      }
    },
    label: function (value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },
    getWordForm: function (n, form1, form2, form3) {
      n = n % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) {
        return form3;
      }
      if (n1 > 1 && n1 < 5) {
        return form2;
      }
      if (n1 == 1) {
        return form1;
      }
      return form3;
    },
  },
};
</script>
