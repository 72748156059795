import { apiMixin } from "./api";

export const onboardingMixin = {
  mixins: [apiMixin],
  methods: {
    deleteOnboardingId(id) {
      if (!this.$store.state.onboardingIdList.includes(id)) {
        return;
      }
      this.$store.commit("deleteOnboardingId", id);
      this.apiPost("/api/onboarding-api/set", {
        id,
      });
    },
    loadOnboardingIdList() {
      this.apiGet("/api/onboarding-api/get").then((response) => {
        if (response.data.success !== true) {
          return;
        }
        this.$store.commit("setOnboardingIdList", response.data.data);
      });
    },
  },
};
