<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="toBack"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <h1
              class="text-nowrap m-0 font-weight-bolder"
              style="font-size: 1rem"
            >
              {{ name.value }}
            </h1>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div>
        <div key="if" v-if="inited">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label for="servingWeight"> Вес порции </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    v-on:click="decServingWeight"
                    v-bind:disabled="loading"
                  >
                    -
                  </button>
                </div>
                <input
                  id="servingWeight"
                  class="form-control"
                  v-bind:class="{ 'is-invalid': servingWeight.error }"
                  v-model="servingWeight.value"
                  v-bind:disabled="loading"
                  v-on:keyup="onKeyUpServingWeight"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> г </span>
                </div>
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    v-on:click="incServingWeight"
                    v-bind:disabled="loading"
                  >
                    +
                  </button>
                </div>
                <div class="invalid-feedback" v-if="servingWeight.error">
                  {{ servingWeight.error }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="time"> Время </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    v-on:click="decTime"
                    v-bind:disabled="loading"
                  >
                    -
                  </button>
                </div>
                <input
                  id="time"
                  class="form-control"
                  v-bind:class="{ 'is-invalid': time.error }"
                  v-model="time.value"
                  v-bind:disabled="loading"
                  v-on:keyup="onKeyUpTime"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    v-on:click="incTime"
                    v-bind:disabled="loading"
                  >
                    +
                  </button>
                </div>
                <div class="invalid-feedback" v-if="time.error">
                  {{ time.error }}
                </div>
              </div>
            </div>
            <div class="form-group mt-3">
              <button
                v-bind:disabled="loading"
                type="submit"
                class="btn btn-lg btn-primary btn-block"
              >
                <span
                  v-if="loading"
                  class="spinner-border"
                  style="width: 1.25rem; height: 1.25rem"
                ></span>
                <span v-else> Записать </span>
              </button>
            </div>
          </form>
          <Transition name="fade">
            <div v-if="product !== null" class="mt-4">
              <div v-if="validServingWeigh !== null">
                <div
                  v-for="(element, index) in mainNutrientList"
                  v-bind:key="index"
                >
                  <div class="d-flex p-2 mb-3">
                    <span>{{ element.label }}</span>
                    <b class="ml-auto pl-1"
                      >{{ element.value }}&nbsp;{{ element.unitLabel }}</b
                    >
                  </div>
                </div>
                <div class="p-2 mb-3" v-if="vitaminNutrientList.length > 0">
                  <b>Витамины</b>
                </div>
                <div
                  v-for="(element, index) in vitaminNutrientList"
                  v-bind:key="index"
                >
                  <div class="d-flex p-2 mb-3">
                    <span>{{ element.label }}</span>
                    <b class="ml-auto pl-1"
                      >{{ element.value }}&nbsp;{{ element.unitLabel }}</b
                    >
                  </div>
                </div>
                <div class="p-2 mb-3" v-if="mineralNutrientList.length > 0">
                  <b>Минералы</b>
                </div>
                <div
                  v-for="(element, index) in mineralNutrientList"
                  v-bind:key="index"
                >
                  <div class="d-flex p-2 mb-3">
                    <span>{{ element.label }}</span>
                    <b class="ml-auto pl-1"
                      >{{ element.value }}&nbsp;{{ element.unitLabel }}</b
                    >
                  </div>
                </div>
                <AdsBlock class="mt-2 mb-3"></AdsBlock>
                <div class="p-2 mb-3" v-if="ingredientList.length > 0">
                  <b>Ингредиенты</b>
                </div>
                <div
                  v-for="(ingredient, index) in ingredientList"
                  v-bind:key="index"
                >
                  <product-ingredient
                    v-bind:product="ingredient"
                  ></product-ingredient>
                </div>
              </div>
              <div class="p-2 mb-3" v-if="images">
                <div id="images" class="carousel slide" data-ride="carousel">
                  <ol class="carousel-indicators" v-if="images.length > 1">
                    <li
                      data-target="images"
                      v-for="(image, index) in images"
                      v-bind:key="index"
                      v-bind:data-slide-to="index"
                      v-bind:class="{ active: index === 0 }"
                    ></li>
                  </ol>
                  <div class="carousel-inner">
                    <div
                      class="carousel-item"
                      v-for="(image, index) in images"
                      v-bind:class="{ active: index === 0 }"
                      v-bind:key="index"
                    >
                      <img class="d-block w-100" v-bind:src="image" />
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#images"
                    role="button"
                    data-slide="prev"
                    v-if="images.length > 1"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Предыдущее</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#images"
                    role="button"
                    data-slide="next"
                    v-if="images.length > 1"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Следующее</span>
                  </a>
                </div>
              </div>
              <div class="p-2 mb-3" v-if="external">
                <b>{{ external.label }}:</b>
                <a v-bind:href="external.url" target="_blank" rel="nofollow">{{
                  external.urlLabel
                }}</a>
              </div>
              <div class="p-2 mb-3" v-if="keywords !== ''">{{ keywords }}</div>
              <div
                class="p-2 mb-3"
                v-if="otherProducts && otherProducts.length > 0"
              >
                <b>Другие</b>
              </div>
              <div v-for="(item, index) in otherProducts" v-bind:key="index">
                <product-other-product
                  v-bind:product="item"
                ></product-other-product>
              </div>
            </div>
          </Transition>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date";
import ProductIngredient from "@/components/product/Ingredient";
import ProductOtherProduct from "@/components/product/OtherProduct";
import { apiMixin } from "@/mixins/api";
import AdsBlock from "@/components/product/AdsBlock.vue";

export default {
  name: "ProductAdd",
  components: {
    AdsBlock,
    ProductIngredient,
    ProductOtherProduct,
  },
  mixins: [dateMixin, productMixin, apiMixin],
  data: function () {
    return {
      date: null,
      id: null,
      name: {
        timer: null,
        value: "",
        error: null,
      },
      servingWeight: {
        timer: null,
        value: null,
        error: null,
      },
      time: {
        timer: null,
        value: null,
        error: null,
      },
      loading: null,
      product: null,
      inited: false,
      loadSpinnerVisible: false,
      jsonLd: null,
      ingredients: null,
      external: null,
      images: null,
      keywords: "",
      otherProducts: null,
    };
  },
  computed: {
    validServingWeigh: function () {
      let value = this.servingWeight.value;
      if (!value) {
        return null;
      }
      let regexp = /^\d+((.|,)\d+)?$/;
      if (!regexp.test(value)) {
        return null;
      }
      value = parseFloat(value);
      if (!isFinite(value) || value === 0) {
        return null;
      }
      return value;
    },
    mainNutrientList: function () {
      return this.createNutrientList(this.product.main);
    },
    vitaminNutrientList: function () {
      return this.createNutrientList(this.product.vitamin);
    },
    mineralNutrientList: function () {
      return this.createNutrientList(this.product.mineral);
    },
    ingredientList: function () {
      let list = [];
      for (let ingredientKey in this.ingredients) {
        let ingredient = this.ingredients[ingredientKey];
        list.push({
          name: ingredient.name,
          servingWeight: Math.round(
            (this.validServingWeigh / 100) * ingredient.servingWeight,
          ),
          protein: ingredient.protein,
          fatTotal: ingredient.fatTotal,
          carbohydrates: ingredient.carbohydrates,
          energyConsumed: ingredient.energyConsumed,
          productId: ingredient.productId,
          productFriendlyUrl: ingredient.productFriendlyUrl,
          productImageUrl128x128: ingredient.productImageUrl128x128,
        });
      }
      return list;
    },
  },
  methods: {
    createNutrientList: function (object) {
      let list = [];
      for (let nutrientKey in object) {
        let nutrient = object[nutrientKey];
        let value = ((this.validServingWeigh / 100) * nutrient.value)
          .toFixed(nutrient.precision)
          .replace(/^(\d+(\.[1-9]+)?)(.*)/, "$1");
        if (value === "0") {
          continue;
        }
        list.push({
          label: nutrient.label,
          unitLabel: nutrient.unitLabel,
          value: value,
        });
      }
      return list;
    },
    validateName: function () {
      if (this.name.value.length > 200) {
        this.name.error = "Максимальная длина названия еды 200 символов";
        return false;
      }
      this.name.error = null;
      return true;
    },
    onKeyUpServingWeight: function () {
      this.servingWeight.error = null;
      clearTimeout(this.servingWeight.timer);
      this.servingWeight.timer = setTimeout(() => {
        this.validateServingWeight();
      }, 700);
    },
    validateServingWeight: function () {
      this.servingWeight.error = null;
      if (!this.servingWeight.value) {
        this.servingWeight.error = "Необходимо ввести вес порции";
        return false;
      }
      let regexp = /^\d+((.|,)\d+)?$/;
      var value = parseFloat(this.servingWeight.value);
      if (!isFinite(value) || !regexp.test(this.servingWeight.value)) {
        this.servingWeight.error =
          "Поле может содержать только положительное число";
        return false;
      }
      return true;
    },
    onKeyUpTime: function () {
      this.time.error = null;
      clearTimeout(this.time.timer);
      this.time.timer = setTimeout(() => {
        this.validateTime();
      }, 700);
    },
    validateTime: function () {
      this.time.error = null;
      if (!this.time.value) {
        this.time.error = "Необходимо ввести время";
        return false;
      }
      let regexp = /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/;
      if (!regexp.test(this.time.value)) {
        this.time.error = "Неверный формат времени";
        return false;
      }
      return true;
    },
    onSubmit: function () {
      let isValidServingWeight = this.validateServingWeight();
      let isValidTime = this.validateTime();
      if (!isValidServingWeight || !isValidTime) {
        return;
      }
      this.$store.commit("changeJournalFoodLastTime", this.time.value);
      this.loading = true;
      this.apiPost("/api/journal-api/add-food", {
        productId: this.id,
        name: this.name.value,
        servingWeight: this.servingWeight.value,
        dateTime: this.getDateString(this.date) + " " + this.time.value,
        query: this.$route.query.query,
      })
        .then((response) => {
          if (
            response.data.status === "error" &&
            response.data.error === "expiration"
          ) {
            this.$router.push({
              path: "/account/payment",
              query: {
                from: "product",
              },
            });
            return;
          }
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          // setTimeout(function () {
          //   ym(51054236, 'reachGoal', 'addFood');
          // });
          this.$router.push({
            path: "/journal",
            query: {
              date: this.getDateString(this.date),
              first: this.$route.query.first,
            },
          });
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    toBack: function () {
      this.$router.push({
        path: "/product/select",
        query: {
          date: this.getDateString(this.date),
          query: this.$route.query.query,
          first: this.$route.query.first,
        },
      });
    },
    decServingWeight: function () {
      let regexp = /^(\d+)$/;
      let result = regexp.exec(this.servingWeight.value);
      if (result === null) {
        this.servingWeight.value = "0";
        return;
      }
      result = parseInt(result[0]) - 10;
      if (result < 0) {
        result = 0;
      }
      this.servingWeight.value = result;
    },
    incServingWeight: function () {
      let regexp = /^(\d+)$/;
      let result = regexp.exec(this.servingWeight.value);
      if (result === null) {
        this.servingWeight.value = "0";
        return;
      }
      result = parseInt(result[0]) + 10;
      if (result < 0) {
        result = 0;
      }
      this.servingWeight.value = result;
    },
    decTime: function () {
      let regexp = /^(\d|0\d|1\d|2[0-3]):([0-5]\d)$/;
      let result = regexp.exec(this.time.value);
      if (result === null) {
        this.time.value = "0:00";
        return;
      }
      let hours = result[1];
      let minutes = result[2];
      if (minutes == 0) {
        if (--hours < 0) {
          return;
        } else {
          this.time.value = hours + ":30";
          return;
        }
      } else if (minutes > 30) {
        this.time.value = hours + ":30";
        return;
      }
      this.time.value = hours + ":00";
    },
    incTime: function () {
      let regexp = /^(\d|0\d|1\d|2[0-3]):([0-5]\d)$/;
      let result = regexp.exec(this.time.value);
      if (result === null) {
        this.time.value = "23:30";
        return;
      }
      let hours = result[1];
      let minutes = result[2];
      if (minutes < 30) {
        this.time.value = hours + ":30";
        return;
      } else if (++hours > 23) {
        return;
      }
      this.time.value = hours + ":00";
    },
    loadProduct: function () {
      let name = this.name.value;
      let servingWeight = this.servingWeight.value;
      let loadSpinnerTimer = null;
      if (name !== null && servingWeight !== null) {
        this.inited = true;
      } else {
        loadSpinnerTimer = setTimeout(() => {
          this.loadSpinnerVisible = true;
        }, 1000);
      }
      this.apiGet("/api/product-api/get", {
        params: {
          id: this.id,
          friendlyUrl: this.$route.params.friendlyUrl,
        },
      })
        .then((response) => {
          if (response.data.success !== true) {
            window.location.href = "/journal";
            return;
          }
          this.images = response.data.data.images;
          this.external = response.data.data.external;
          this.product = response.data.data.nutrient;
          this.ingredients = response.data.data.ingredients;
          this.keywords = response.data.data.keywords;
          this.otherProducts = response.data.data.otherProducts;
          this.name.value = response.data.data.name;
          if (servingWeight === null) {
            this.servingWeight.value = response.data.data.servingWeight;
            this.validateServingWeight();
          }
          if (loadSpinnerTimer !== null) {
            clearTimeout(loadSpinnerTimer);
            this.inited = true;
          }
          try {
            this.updateMeta(
              response.data.data.name,
              response.data.data.nutrient.main.energyConsumed.value,
            );
          } catch (e) {
            console.log(e);
          }
          if (
            this.$route.params.friendlyUrl !== response.data.data.friendlyUrl
          ) {
            let query = this.$route.query;
            delete query.id;
            this.$router.replace({
              path:
                "/product/" + this.id + "/" + response.data.data.friendlyUrl,
              query: this.$route.query,
            });
          }
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    loadJsonLd: function () {
      this.apiGet("/api/product-api/get-json-ld", {
        params: {
          id: this.id,
        },
      }).then((response) => {
        if (response.data.success !== true) {
          return;
        }
        this.jsonLd = response.data.data;
        const existingScript = document.getElementById("json-ld");
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.textContent = JSON.stringify(this.jsonLd);
        script.id = "json-ld";
        document.head.appendChild(script);
      });
    },
    updateMeta: function (name, energyConsumed) {
      if (typeof name !== "string") {
        return;
      }
      let metaName = name.trim();
      if (metaName.length === 0) {
        return;
      }
      metaName = metaName.charAt(0).toUpperCase() + metaName.slice(1);
      document.title = metaName + " — калорийность, пищевая ценность — Фитаб";
      let metaEnergyConsumed = 0;
      if (typeof energyConsumed === "number") {
        metaEnergyConsumed = Math.floor(energyConsumed);
      }
      let metaDescription =
        metaName +
        ". Энергетическая ценность (калорийность) составляет " +
        Math.floor(metaEnergyConsumed) +
        " ккал на 100 грамм продукта.";
      let metaDescriptionElement = document.querySelector(
        'meta[name="description"]',
      );
      if (metaDescriptionElement instanceof Element) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metaDescription);
      } else {
        let newElement = document.createElement("meta");
        newElement.setAttribute("name", "description");
        newElement.setAttribute("content", metaDescription);
        document.head.appendChild(newElement);
      }
    },
    pad: function (number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },
  },
  mounted: function () {
    let date = new Date(this.$route.query.date);
    if (isNaN(date)) {
      date = new Date();
    }
    this.date = date;

    let id = this.$route.query.id;
    if (!id) {
      id = this.$route.params.id;
    }
    if (!id) {
      window.location.href = "/journal";
      return;
    }
    this.id = id;

    if (typeof this.$store.state.journalFoodLastTime === "string") {
      this.time.value = this.$store.state.journalFoodLastTime;
    } else {
      let now = new Date();

      this.time.value = now.getHours() + ":" + this.pad(now.getMinutes());
    }

    if (this.$route.query.name !== undefined) {
      this.name.value = this.$route.query.name;
    }

    if (this.$route.query.serving_weight !== undefined) {
      this.servingWeight.value = this.$route.query.serving_weight;
    }

    this.validateServingWeight();
    this.loadProduct();
    this.loadJsonLd();
  },
};
</script>
