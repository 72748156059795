import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    dateLabelMonths: [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ],
    date: new Date(),
    journalFoodLastTime: null,
    onboardingIdList: [],
  },
  mutations: {
    changeDate(state, date) {
      state.date = date;
    },
    setOnboardingIdList(state, list) {
      state.onboardingIdList = list;
    },
    deleteOnboardingId(state, id) {
      const { onboardingIdList } = state;
      const index = onboardingIdList.indexOf(id);
      if (index === -1) {
        return;
      }
      onboardingIdList.splice(index, 1);
      state.onboardingIdList = onboardingIdList;
    },
    changeJournalFoodLastTime(state, time) {
      state.journalFoodLastTime = time;
      localStorage.setItem("journalFoodLastTime", time);
    },
  },
  actions: {},
  modules: {},
});
