<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <form v-on:submit.prevent="onSubmit">
        <div
          class="fixed-top d-flex justify-content-center border-bottom bg-white"
          style="opacity: 0.95"
        >
          <div class="col-12 p-0" style="max-width: 500px">
            <div
              class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
            >
              <button
                class="text-decoration-none text-reset btn p-0"
                v-on:click="back"
              >
                <img
                  src="@/assets/image/icon/chevron-left.svg"
                  width="24px"
                  height="24px"
                />
              </button>
              <b>Шаг 5/{{ stepCount }}: Cуточные нормы</b>
              <span
                v-if="loading"
                class="spinner-border"
                style="width: 24px; height: 24px"
              ></span>
              <button
                v-else
                class="text-decoration-none text-reset btn p-0"
                v-on:click="onSubmit"
              >
                <img
                  src="@/assets/image/icon/check.svg"
                  width="24px"
                  height="24px"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="text-center">
          <img src="@/assets/image/start/5.jpg" width="107px" height="107px" />
        </div>
        <Transition name="fade">
          <div key="if" v-if="inited">
            <common-element-input
              v-bind:element="energyConsumed"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="proteinPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatTotalPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatSaturatedPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatMonounsaturatedPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatPolyunsaturatedPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatPolyunsaturatedOmega3Percent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatPolyunsaturatedOmega6Percent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fatTransPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="carbohydratesPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="sugarPercent"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUpPercent"
            ></common-element-input>
            <common-element-input
              v-bind:element="fiber"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="water"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="cholesterol"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="caffeine"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <div class="mb-3">
              <b>Витамины</b>
            </div>
            <common-element-input
              v-bind:element="vitaminA"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="thiamin"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="riboflavin"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="niacin"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="pantothenicAcid"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminB6"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="biotin"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminB12"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminC"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminD"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminE"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="vitaminK"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="folate"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <div class="mb-3">
              <b>Минеральные вещества</b>
            </div>
            <common-element-input
              v-bind:element="calcium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="chloride"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="iron"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="magnesium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="phosphorus"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="potassium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="sodium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="zinc"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="chromium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="copper"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="iodine"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="manganese"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="molybdenum"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <common-element-input
              v-bind:element="selenium"
              v-bind:loadingFlag="loading"
              v-bind:onKeyUp="keyUp"
            ></common-element-input>
            <div class="form-group mt-3">
              <button
                v-bind:disabled="loading"
                type="submit"
                class="btn btn-lg btn-primary btn-block"
              >
                <span
                  v-if="loading"
                  class="spinner-border"
                  style="width: 1.25rem; height: 1.25rem"
                ></span>
                <span v-else>Сохранить</span>
              </button>
            </div>
          </div>
          <div key="else" class="d-flex justify-content-center" v-else>
            <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
              <span class="sr-only">Загрузка...</span>
            </div>
          </div>
        </Transition>
      </form>
    </div>
  </div>
</template>

<script>
import CommonElementInput from "@/components/common/ElementInput.vue";
import { startMixin } from "@/mixins/start";
import { apiMixin } from "@/mixins/api";

export default {
  name: "StartElementsSetting",
  mixins: [startMixin, apiMixin],
  components: {
    CommonElementInput,
  },
  data: function () {
    return {
      energyConsumed: {
        name: "energyConsumed",
        label: "Энергетическая ценность",
        unit: "ккал",
      },
      proteinPercent: {
        name: "proteinPercent",
        label: "Белки",
        unit: "% от ккал",
      },
      fatTotalPercent: {
        name: "fatTotalPercent",
        label: "Жиры",
        unit: "% от ккал",
      },
      fatSaturatedPercent: {
        name: "fatSaturatedPercent",
        label: "Насыщенные жиры",
        unit: "% от ккал",
      },
      fatMonounsaturatedPercent: {
        name: "fatMonounsaturatedPercent",
        label: "Мононенасыщенные жиры",
        unit: "% от ккал",
      },
      fatPolyunsaturatedPercent: {
        name: "fatPolyunsaturatedPercent",
        label: "Полиненасыщенные жиры",
        unit: "% от ккал",
      },
      fatPolyunsaturatedOmega3Percent: {
        name: "fatPolyunsaturatedOmega3Percent",
        label: "Омега 3",
        unit: "% от ккал",
      },
      fatPolyunsaturatedOmega6Percent: {
        name: "fatPolyunsaturatedOmega6Percent",
        label: "Омега 6",
        unit: "% от ккал",
      },
      fatTransPercent: {
        name: "fatTransPercent",
        label: "Трансжиры",
        unit: "% от ккал",
      },
      carbohydratesPercent: {
        name: "carbohydratesPercent",
        label: "Углеводы",
        unit: "% от ккал",
      },
      sugarPercent: {
        name: "sugarPercent",
        label: "Сахар",
        unit: "% от ккал",
      },
      fiber: {
        name: "fiber",
        label: "Клетчатка",
        unit: "г",
      },
      water: {
        name: "water",
        label: "Вода",
        unit: "г",
      },
      cholesterol: {
        name: "cholesterol",
        label: "Холестерин",
        unit: "мг",
      },
      caffeine: {
        name: "caffeine",
        label: "Кофеин",
        unit: "мг",
      },
      vitaminA: {
        name: "vitaminA",
        label: "Витамин A",
        unit: "мкг",
      },
      thiamin: {
        name: "thiamin",
        label: "Витамин B1",
        unit: "мг",
      },
      riboflavin: {
        name: "riboflavin",
        label: "Витамин B2",
        unit: "мг",
      },
      niacin: {
        name: "niacin",
        label: "Никотиновая кислота",
        unit: "мг",
      },
      pantothenicAcid: {
        name: "pantothenicAcid",
        label: "Пантотеновая кислота",
        unit: "мг",
      },
      vitaminB6: {
        name: "vitaminB6",
        label: "Витамин B6",
        unit: "мг",
      },
      biotin: {
        name: "biotin",
        label: "Витамин H",
        unit: "мкг",
      },
      vitaminB12: {
        name: "vitaminB12",
        label: "Витамин B12",
        unit: "мкг",
      },
      vitaminC: {
        name: "vitaminC",
        label: "Витамин C",
        unit: "мг",
      },
      vitaminD: {
        name: "vitaminD",
        label: "Витамин D",
        unit: "мкг",
      },
      vitaminE: {
        name: "vitaminE",
        label: "Витамин E",
        unit: "мг",
      },
      vitaminK: {
        name: "vitaminK",
        label: "Витамин K",
        unit: "мкг",
      },
      folate: {
        name: "folate",
        label: "Фолат",
        unit: "мкг",
      },
      calcium: {
        name: "calcium",
        label: "Кальций",
        unit: "мг",
      },
      chloride: {
        name: "chloride",
        label: "Хлориды",
        unit: "мг",
      },
      iron: {
        name: "iron",
        label: "Железо",
        unit: "мг",
      },
      magnesium: {
        name: "magnesium",
        label: "Магний",
        unit: "мг",
      },
      phosphorus: {
        name: "phosphorus",
        label: "Фосфор",
        unit: "мг",
      },
      potassium: {
        name: "potassium",
        label: "Калий",
        unit: "мг",
      },
      sodium: {
        name: "sodium",
        label: "Натрий",
        unit: "мг",
      },
      zinc: {
        name: "zinc",
        label: "Цинк",
        unit: "мг",
      },
      chromium: {
        name: "chromium",
        label: "Хром",
        unit: "мкг",
      },
      copper: {
        name: "copper",
        label: "Медь",
        unit: "мг",
      },
      iodine: {
        name: "iodine",
        label: "Йод",
        unit: "мкг",
      },
      manganese: {
        name: "manganese",
        label: "Марганец",
        unit: "мг",
      },
      molybdenum: {
        name: "molybdenum",
        label: "Молибден",
        unit: "мкг",
      },
      selenium: {
        name: "selenium",
        label: "Селен",
        unit: "мкг",
      },
      loading: false,
      inited: false,
      loadSpinnerVisible: false,
    };
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    this.apiGet("/api/elements-setting-api/load")
      .then((response) => {
        if (response.data.status != "ok") {
          window.location.href = "/journal";
          return;
        }
        if (response.data.items.energy_consumed) {
          this.energyConsumed.value = response.data.items.energy_consumed;
          this.energyConsumed.enable = true;
        }
        if (response.data.items.protein_percent) {
          this.proteinPercent.value = response.data.items.protein_percent;
          this.proteinPercent.enable = true;
        }
        if (response.data.items.fat_total_percent) {
          this.fatTotalPercent.value = response.data.items.fat_total_percent;
          this.fatTotalPercent.enable = true;
        }
        if (response.data.items.fat_saturated_percent) {
          this.fatSaturatedPercent.value =
            response.data.items.fat_saturated_percent;
          this.fatSaturatedPercent.enable = true;
        }
        if (response.data.items.fat_monounsaturated_percent) {
          this.fatMonounsaturatedPercent.value =
            response.data.items.fat_monounsaturated_percent;
          this.fatMonounsaturatedPercent.enable = true;
        }
        if (response.data.items.fat_polyunsaturated_percent) {
          this.fatPolyunsaturatedPercent.value =
            response.data.items.fat_polyunsaturated_percent;
          this.fatPolyunsaturatedPercent.enable = true;
        }
        if (response.data.items.fat_polyunsaturated_omega3_percent) {
          this.fatPolyunsaturatedOmega3Percent.value =
            response.data.items.fat_polyunsaturated_omega3_percent;
          this.fatPolyunsaturatedOmega3Percent.enable = true;
        }
        if (response.data.items.fat_polyunsaturated_omega6_percent) {
          this.fatPolyunsaturatedOmega6Percent.value =
            response.data.items.fat_polyunsaturated_omega6_percent;
          this.fatPolyunsaturatedOmega6Percent.enable = true;
        }
        if (response.data.items.fat_trans_percent) {
          this.fatTransPercent.value = response.data.items.fat_trans_percent;
          this.fatTransPercent.enable = true;
        }
        if (response.data.items.carbohydrates_percent) {
          this.carbohydratesPercent.value =
            response.data.items.carbohydrates_percent;
          this.carbohydratesPercent.enable = true;
        }
        if (response.data.items.sugar_percent) {
          this.sugarPercent.value = response.data.items.sugar_percent;
          this.sugarPercent.enable = true;
        }
        if (response.data.items.fiber) {
          this.fiber.value = response.data.items.fiber;
          this.fiber.enable = true;
        }
        if (response.data.items.water) {
          this.water.value = response.data.items.water;
          this.water.enable = true;
        }
        if (response.data.items.cholesterol) {
          this.cholesterol.value = response.data.items.cholesterol;
          this.cholesterol.enable = true;
        }
        if (response.data.items.caffeine) {
          this.caffeine.value = response.data.items.caffeine;
          this.caffeine.enable = true;
        }
        if (response.data.items.vitamin_a) {
          this.vitaminA.value = response.data.items.vitamin_a;
          this.vitaminA.enable = true;
        }
        if (response.data.items.thiamin) {
          this.thiamin.value = response.data.items.thiamin;
          this.thiamin.enable = true;
        }
        if (response.data.items.riboflavin) {
          this.riboflavin.value = response.data.items.riboflavin;
          this.riboflavin.enable = true;
        }
        if (response.data.items.niacin) {
          this.niacin.value = response.data.items.niacin;
          this.niacin.enable = true;
        }
        if (response.data.items.pantothenic_acid) {
          this.pantothenicAcid.value = response.data.items.pantothenic_acid;
          this.pantothenicAcid.enable = true;
        }
        if (response.data.items.vitamin_b6) {
          this.vitaminB6.value = response.data.items.vitamin_b6;
          this.vitaminB6.enable = true;
        }
        if (response.data.items.biotin) {
          this.biotin.value = response.data.items.biotin;
          this.biotin.enable = true;
        }
        if (response.data.items.vitamin_b12) {
          this.vitaminB12.value = response.data.items.vitamin_b12;
          this.vitaminB12.enable = true;
        }
        if (response.data.items.vitamin_c) {
          this.vitaminC.value = response.data.items.vitamin_c;
          this.vitaminC.enable = true;
        }
        if (response.data.items.vitamin_d) {
          this.vitaminD.value = response.data.items.vitamin_d;
          this.vitaminD.enable = true;
        }
        if (response.data.items.vitamin_e) {
          this.vitaminE.value = response.data.items.vitamin_e;
          this.vitaminE.enable = true;
        }
        if (response.data.items.vitamin_k) {
          this.vitaminK.value = response.data.items.vitamin_k;
          this.vitaminK.enable = true;
        }
        if (response.data.items.folate) {
          this.folate.value = response.data.items.folate;
          this.folate.enable = true;
        }
        if (response.data.items.calcium) {
          this.calcium.value = response.data.items.calcium;
          this.calcium.enable = true;
        }
        if (response.data.items.chloride) {
          this.chloride.value = response.data.items.chloride;
          this.chloride.enable = true;
        }
        if (response.data.items.iron) {
          this.iron.value = response.data.items.iron;
          this.iron.enable = true;
        }
        if (response.data.items.magnesium) {
          this.magnesium.value = response.data.items.magnesium;
          this.magnesium.enable = true;
        }
        if (response.data.items.phosphorus) {
          this.phosphorus.value = response.data.items.phosphorus;
          this.phosphorus.enable = true;
        }
        if (response.data.items.potassium) {
          this.potassium.value = response.data.items.potassium;
          this.potassium.enable = true;
        }
        if (response.data.items.sodium) {
          this.sodium.value = response.data.items.sodium;
          this.sodium.enable = true;
        }
        if (response.data.items.zinc) {
          this.zinc.value = response.data.items.zinc;
          this.zinc.enable = true;
        }
        if (response.data.items.chromium) {
          this.chromium.value = response.data.items.chromium;
          this.chromium.enable = true;
        }
        if (response.data.items.copper) {
          this.copper.value = response.data.items.copper;
          this.copper.enable = true;
        }
        if (response.data.items.iodine) {
          this.iodine.value = response.data.items.iodine;
          this.iodine.enable = true;
        }
        if (response.data.items.manganese) {
          this.manganese.value = response.data.items.manganese;
          this.manganese.enable = true;
        }
        if (response.data.items.molybdenum) {
          this.molybdenum.value = response.data.items.molybdenum;
          this.molybdenum.enable = true;
        }
        if (response.data.items.selenium) {
          this.selenium.value = response.data.items.selenium;
          this.selenium.enable = true;
        }
        this.inited = true;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
  methods: {
    keyUp: function (element) {
      element.error = null;
      clearTimeout(element.timer);
      element.timer = setTimeout(() => {
        this.validate(element);
      }, 700);
    },
    validate: function (element) {
      element.error = null;
      if (!element.enable) {
        return true;
      }
      if (!element.value) {
        element.error = "Поле обязательно для заполнения";
        return false;
      }
      let regexp = /^[0-9]*(.|,)?[0-9]+$/;
      var value = parseFloat(element.value);
      if (!isFinite(value) || !regexp.test(element.value)) {
        element.error = "Поле может содержать только положительное число";
        return false;
      }
      return true;
    },
    keyUpPercent: function (element) {
      element.error = null;
      clearTimeout(element.timer);
      element.timer = setTimeout(() => {
        this.validatePercent(element);
      }, 700);
    },
    validatePercent: function (element) {
      element.error = null;
      if (!element.enable) {
        return true;
      }
      if (!element.value) {
        element.error = "Поле обязательно для заполнения";
        return false;
      }
      let regexp = /^[0-9]*(.|,)?[0-9]+$/;
      var value = parseFloat(element.value);
      if (!isFinite(value) || !regexp.test(element.value)) {
        element.error = "Поле может содержать только положительное число";
        return false;
      }
      if (value < 1 || value > 100) {
        element.error =
          "Поле может содержать только положительное число до 100";
        return false;
      }
      return true;
    },
    onSubmit: function () {
      let isValidEnergyConsumed = this.validate(this.energyConsumed);
      let isValidProteinPercent = this.validatePercent(this.proteinPercent);
      let isValidFatTotalPercent = this.validatePercent(this.fatTotalPercent);
      let isValidFatSaturatedPercent = this.validatePercent(
        this.fatSaturatedPercent,
      );
      let isValidFatMonounsaturatedPercent = this.validatePercent(
        this.fatMonounsaturatedPercent,
      );
      let isValidFatPolyunsaturatedPercent = this.validatePercent(
        this.fatPolyunsaturatedPercent,
      );
      let isValidFatPolyunsaturatedOmega3Percent = this.validatePercent(
        this.fatPolyunsaturatedOmega3Percent,
      );
      let isValidFatPolyunsaturatedOmega6Percent = this.validatePercent(
        this.fatPolyunsaturatedOmega6Percent,
      );
      let isValidFatTransPercent = this.validatePercent(this.fatTransPercent);
      let isValidCarbohydratesPercent = this.validatePercent(
        this.carbohydratesPercent,
      );
      let isValidSugarPercent = this.validatePercent(this.sugarPercent);
      let isValidFiber = this.validate(this.fiber);
      let isValidWater = this.validate(this.water);
      let isValidCholesterol = this.validate(this.cholesterol);
      let isValidCaffeine = this.validate(this.caffeine);
      let isValidVitaminA = this.validate(this.vitaminA);
      let isValidThiamin = this.validate(this.thiamin);
      let isValidRiboflavin = this.validate(this.riboflavin);
      let isValidNiacin = this.validate(this.niacin);
      let isValidPantothenicAcid = this.validate(this.pantothenicAcid);
      let isValidVitaminB6 = this.validate(this.vitaminB6);
      let isValidBiotin = this.validate(this.biotin);
      let isValidVitaminB12 = this.validate(this.vitaminB12);
      let isValidVitaminC = this.validate(this.vitaminC);
      let isValidVitaminD = this.validate(this.vitaminD);
      let isValidVitaminE = this.validate(this.vitaminE);
      let isValidVitaminK = this.validate(this.vitaminK);
      let isValidFolate = this.validate(this.folate);
      let isValidCalcium = this.validate(this.calcium);
      let isValidChloride = this.validate(this.chloride);
      let isValidIron = this.validate(this.iron);
      let isValidMagnesium = this.validate(this.magnesium);
      let isValidPhosphorus = this.validate(this.phosphorus);
      let isValidPotassium = this.validate(this.potassium);
      let isValidSodium = this.validate(this.sodium);
      let isValidZinc = this.validate(this.zinc);
      let isValidChromium = this.validate(this.chromium);
      let isValidCopper = this.validate(this.copper);
      let isValidIodine = this.validate(this.iodine);
      let isValidManganese = this.validate(this.manganese);
      let isValidMolybdenum = this.validate(this.molybdenum);
      let isValidSelenium = this.validate(this.selenium);
      if (
        !isValidEnergyConsumed ||
        !isValidProteinPercent ||
        !isValidFatTotalPercent ||
        !isValidFatSaturatedPercent ||
        !isValidFatMonounsaturatedPercent ||
        !isValidFatPolyunsaturatedPercent ||
        !isValidFatPolyunsaturatedOmega3Percent ||
        !isValidFatPolyunsaturatedOmega6Percent ||
        !isValidFatTransPercent ||
        !isValidCarbohydratesPercent ||
        !isValidSugarPercent ||
        !isValidFiber ||
        !isValidWater ||
        !isValidCholesterol ||
        !isValidCaffeine ||
        !isValidVitaminA ||
        !isValidThiamin ||
        !isValidRiboflavin ||
        !isValidNiacin ||
        !isValidPantothenicAcid ||
        !isValidVitaminB6 ||
        !isValidBiotin ||
        !isValidVitaminB12 ||
        !isValidVitaminC ||
        !isValidVitaminD ||
        !isValidVitaminE ||
        !isValidVitaminK ||
        !isValidFolate ||
        !isValidCalcium ||
        !isValidChloride ||
        !isValidIron ||
        !isValidMagnesium ||
        !isValidPhosphorus ||
        !isValidPotassium ||
        !isValidSodium ||
        !isValidZinc ||
        !isValidChromium ||
        !isValidCopper ||
        !isValidIodine ||
        !isValidManganese ||
        !isValidMolybdenum ||
        !isValidSelenium
      ) {
        return;
      }
      this.loading = true;
      let data = {};
      if (this.energyConsumed.enable) {
        data["energy_consumed"] = this.energyConsumed.value;
      }
      if (this.proteinPercent.enable) {
        data["protein_percent"] = this.proteinPercent.value;
      }
      if (this.fatTotalPercent.enable) {
        data["fat_total_percent"] = this.fatTotalPercent.value;
      }
      if (this.fatSaturatedPercent.enable) {
        data["fat_saturated_percent"] = this.fatSaturatedPercent.value;
      }
      if (this.fatMonounsaturatedPercent.enable) {
        data["fat_monounsaturated_percent"] =
          this.fatMonounsaturatedPercent.value;
      }
      if (this.fatPolyunsaturatedPercent.enable) {
        data["fat_polyunsaturated_percent"] =
          this.fatPolyunsaturatedPercent.value;
      }
      if (this.fatPolyunsaturatedOmega3Percent.enable) {
        data["fat_polyunsaturated_omega3_percent"] =
          this.fatPolyunsaturatedOmega3Percent.value;
      }
      if (this.fatPolyunsaturatedOmega6Percent.enable) {
        data["fat_polyunsaturated_omega6_percent"] =
          this.fatPolyunsaturatedOmega6Percent.value;
      }
      if (this.fatTransPercent.enable) {
        data["fat_trans_percent"] = this.fatTransPercent.value;
      }
      if (this.carbohydratesPercent.enable) {
        data["carbohydrates_percent"] = this.carbohydratesPercent.value;
      }
      if (this.sugarPercent.enable) {
        data["sugar_percent"] = this.sugarPercent.value;
      }
      if (this.fiber.enable) {
        data["fiber"] = this.fiber.value;
      }
      if (this.water.enable) {
        data["water"] = this.water.value;
      }
      if (this.cholesterol.enable) {
        data["cholesterol"] = this.cholesterol.value;
      }
      if (this.caffeine.enable) {
        data["caffeine"] = this.caffeine.value;
      }
      if (this.vitaminA.enable) {
        data["vitamin_a"] = this.vitaminA.value;
      }
      if (this.thiamin.enable) {
        data["thiamin"] = this.thiamin.value;
      }
      if (this.riboflavin.enable) {
        data["riboflavin"] = this.riboflavin.value;
      }
      if (this.niacin.enable) {
        data["niacin"] = this.niacin.value;
      }
      if (this.pantothenicAcid.enable) {
        data["pantothenic_acid"] = this.pantothenicAcid.value;
      }
      if (this.vitaminB6.enable) {
        data["vitamin_b6"] = this.vitaminB6.value;
      }
      if (this.biotin.enable) {
        data["biotin"] = this.biotin.value;
      }
      if (this.vitaminB12.enable) {
        data["vitamin_b12"] = this.vitaminB12.value;
      }
      if (this.vitaminC.enable) {
        data["vitamin_c"] = this.vitaminC.value;
      }
      if (this.vitaminD.enable) {
        data["vitamin_d"] = this.vitaminD.value;
      }
      if (this.vitaminE.enable) {
        data["vitamin_e"] = this.vitaminE.value;
      }
      if (this.vitaminK.enable) {
        data["vitamin_k"] = this.vitaminK.value;
      }
      if (this.folate.enable) {
        data["folate"] = this.folate.value;
      }
      if (this.calcium.enable) {
        data["calcium"] = this.calcium.value;
      }
      if (this.chloride.enable) {
        data["chloride"] = this.chloride.value;
      }
      if (this.iron.enable) {
        data["iron"] = this.iron.value;
      }
      if (this.magnesium.enable) {
        data["magnesium"] = this.magnesium.value;
      }
      if (this.phosphorus.enable) {
        data["phosphorus"] = this.phosphorus.value;
      }
      if (this.potassium.enable) {
        data["potassium"] = this.potassium.value;
      }
      if (this.sodium.enable) {
        data["sodium"] = this.sodium.value;
      }
      if (this.zinc.enable) {
        data["zinc"] = this.zinc.value;
      }
      if (this.chromium.enable) {
        data["chromium"] = this.chromium.value;
      }
      if (this.copper.enable) {
        data["copper"] = this.copper.value;
      }
      if (this.iodine.enable) {
        data["iodine"] = this.iodine.value;
      }
      if (this.manganese.enable) {
        data["manganese"] = this.manganese.value;
      }
      if (this.molybdenum.enable) {
        data["molybdenum"] = this.molybdenum.value;
      }
      if (this.selenium.enable) {
        data["selenium"] = this.selenium.value;
      }
      this.apiPost("/api/elements-setting-api/save", data)
        .then((response) => {
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          this.routerPushWithCurrentQuery("/start/time-zone");
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.routerPushWithCurrentQuery("/start/body");
    },
  },
};
</script>
