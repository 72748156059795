<template>
  <button
    class="btn mb-2 p-2 list-group-item-action text-reset border-0"
    v-on:click="openClick"
  >
    <img
      v-if="product.image"
      v-bind:src="product.image.url1024x1024"
      width="100%"
      height="100%"
      class="rounded-top mb-2"
    />
    <div class="media">
      <div class="media-body">
        <div class="mb-0 d-flex">
          <span>{{ product.name }}</span>
          <b v-if="servingEnergyConsumed > 0" class="pl-1 ml-auto">{{
            servingEnergyConsumed
          }}</b>
        </div>
        <div>
          <span class="text-muted"
            >{{ Math.round(product.servingWeight, 1) }} г</span
          >
        </div>
        <div
          v-if="
            proteinPercent > 0 ||
            fatTotalPercent > 0 ||
            carbohydratesPercent > 0
          "
          class="progress mt-1"
          style="height: 7px"
        >
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            v-bind:style="{ width: proteinPercent + '%' }"
            v-bind:aria-valuenow="proteinPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="progress-bar bg-warning"
            role="progressbar"
            v-bind:style="{ width: fatTotalPercent + '%' }"
            v-bind:aria-valuenow="fatTotalPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="progress-bar bg-success"
            role="progressbar"
            v-bind:style="{ width: carbohydratesPercent + '%' }"
            v-bind:aria-valuenow="carbohydratesPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date.js";

export default {
  name: "JournalProduct",
  props: ["product"],
  mixins: [productMixin, dateMixin],
  computed: {
    date: function () {
      return this.$store.state.date;
    },
    energyConsumed: function () {
      return this.getEnergyConsumed(this.product.energyConsumed);
    },
    servingEnergyConsumed: function () {
      return Math.round(
        (this.getEnergyConsumed(this.product.energyConsumed) / 100) *
          this.product.servingWeight,
      );
    },
    proteinPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getProteinEnergy(this.product.protein) / this.energyConsumed) *
          100,
      );
    },
    fatTotalPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getFatTotalEnergy(this.product.fatTotal) / this.energyConsumed) *
          100,
      );
    },
    carbohydratesPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getCarbohydratesEnergy(this.product.carbohydrates) /
          this.energyConsumed) *
          100,
      );
    },
  },
  methods: {
    openClick: function () {
      this.$router.push({
        path: "/journal/show",
        query: {
          id: this.product.id,
        },
      });
    },
  },
};
</script>
