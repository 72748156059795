<template>
  <a
    class="btn mb-2 p-2 list-group-item-action text-reset border-0"
    v-on:click.prevent="openClick"
    v-bind:href="productHref"
  >
    <div class="media mr-2 ml-2">
      <img
        v-if="product.image"
        v-bind:src="product.image.url128x128"
        width="120px"
        height="120px"
        class="rounded-lg align-self-center mr-2"
      />
      <div class="media-body">
        <div class="mb-0 d-flex">
          <span class="d-flex align-items-center">
            <span>
              {{ product.name }}
            </span>
          </span>
          <b v-if="servingEnergyConsumed > 0" class="pl-1 ml-auto">
            {{ servingEnergyConsumed }}
          </b>
        </div>
        <div class="d-flex align-items-center">
          <span class="text-muted">
            {{ Math.round(product.servingWeight, 1) }} г
          </span>
          <div class="ml-auto">
            <img
              class="ml-1"
              v-if="product.isVerified"
              src="@/assets/image/icon/verified-product.svg"
              width="16px"
              height="16px"
            />
            <img
              class="ml-1"
              v-if="product.isOwned"
              src="@/assets/image/icon/owned-product.svg"
              width="16px"
              height="16px"
            />
          </div>
        </div>
        <div
          v-if="
            proteinPercent > 0 ||
            fatTotalPercent > 0 ||
            carbohydratesPercent > 0
          "
          class="progress mt-1"
          style="height: 7px"
        >
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            v-bind:style="{ width: proteinPercent + '%' }"
            v-bind:aria-valuenow="proteinPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="progress-bar bg-warning"
            role="progressbar"
            v-bind:style="{ width: fatTotalPercent + '%' }"
            v-bind:aria-valuenow="fatTotalPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="progress-bar bg-success"
            role="progressbar"
            v-bind:style="{ width: carbohydratesPercent + '%' }"
            v-bind:aria-valuenow="carbohydratesPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date.js";
import { onboardingMixin } from "@/mixins/onboarding.js";

export default {
  name: "ProductProduct",
  props: ["product", "query"],
  mixins: [productMixin, dateMixin, onboardingMixin],
  computed: {
    productHref: function () {
      let path = "/product/" + this.product.id;
      if (this.product.friendlyUrl) {
        path = path + "/" + this.product.friendlyUrl;
      }
      return path;
    },
    date: function () {
      return this.$store.state.date;
    },
    energyConsumed: function () {
      return this.getEnergyConsumed(this.product.energyConsumed);
    },
    servingEnergyConsumed: function () {
      return Math.round(
        (this.getEnergyConsumed(this.product.energyConsumed) / 100) *
          this.product.servingWeight,
      );
    },
    proteinPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getProteinEnergy(this.product.protein) / this.energyConsumed) *
          100,
      );
    },
    fatTotalPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getFatTotalEnergy(this.product.fatTotal) / this.energyConsumed) *
          100,
      );
    },
    carbohydratesPercent: function () {
      if (this.energyConsumed == 0) {
        return 0;
      }
      return Math.round(
        (this.getCarbohydratesEnergy(this.product.carbohydrates) /
          this.energyConsumed) *
          100,
      );
    },
  },
  methods: {
    openClick: function () {
      this.deleteOnboardingId(1);
      this.$router.push({
        path: this.productHref,
        query: {
          date: this.getDateString(this.date),
          query: this.query,
          name: this.product.name,
          serving_weight: this.product.servingWeight,
        },
      });
    },
  },
};
</script>
