<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
              v-if="$route.query.first !== '1'"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <div style="width: 24px; height: 24px" v-else></div>
            <b>Шаг 1/{{ stepCount }}: Цель</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <img src="@/assets/image/start/1.jpg" width="107px" height="107px" />
      </div>
      <div class="p-2 mb-3">
        Для начала выберете цель, которую хотите достичь.
      </div>
      <div class="list-group mb-3 text-center">
        <button
          v-on:click="goalLose"
          class="list-group-item list-group-item-action text-body"
        >
          Похудеть
        </button>
        <button
          v-on:click="goalGain"
          class="list-group-item list-group-item-action text-body"
        >
          Набрать вес
        </button>
        <button
          v-on:click="goalMaintain"
          class="list-group-item list-group-item-action text-body"
        >
          Оставаться в форме
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { startMixin } from "@/mixins/start";

export default {
  name: "StartGoal",
  mixins: [startMixin],
  methods: {
    goalLose: function () {
      this.next(1);
    },
    goalGain: function () {
      this.next(3);
    },
    goalMaintain: function () {
      this.next(2);
    },
    next: function (goal) {
      this.routerPushWithCurrentQuery("/start/gender", { goal });
    },
    back: function () {
      this.$router.push("/journal");
    },
  },
};
</script>
