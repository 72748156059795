<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="onBackClicked"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Удаление записи</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="name !== null">
          <div class="mb-3 p-2">
            Запись «{{ name }}» будет удалена без возможности восстановления.
          </div>
          <button
            v-bind:disabled="isLoading"
            class="btn btn-lg btn-danger btn-block"
            v-on:click="onDeleteButtonClicked"
          >
            <span
              v-if="isLoading"
              class="spinner-border"
              style="width: 1.25rem; height: 1.25rem"
            ></span>
            <span v-else>Удалить</span>
          </button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "JournalDelete",
  data: function () {
    return {
      name: null,
      id: null,
      isLoading: false,
    };
  },
  mixins: [apiMixin],
  methods: {
    onBackClicked: function () {
      this.$router.push({
        path: "/journal/show",
        query: {
          id: this.id,
        },
      });
    },
    onDeleteButtonClicked: function () {
      this.isLoading = true;
      this.apiPost("/api/journal-product-api/delete", {
        id: this.id,
      })
        .then((response) => {
          if (response.data.success !== true) {
            window.location.href = "/journal";
            return;
          }
          this.$router.push({
            path: "/journal",
          });
          this.isLoading = false;
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
  },
  mounted: function () {
    let name = this.$route.query.name;
    let id = this.$route.query.id;
    if (!name || !id) {
      window.location.href = "/journal";
    }
    this.name = name;
    this.id = id;
  },
};
</script>
