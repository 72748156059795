<template>
  <div class="p-2">
    <div class="p-2 mb-3">
      <a href="/"
        ><img src="@/assets/image/index_logo.svg" width="150px" height="40px"
      /></a>
    </div>
    <div class="p-2 mb-3">
      <div>
        <b>Сеть недоступна.</b>
      </div>
      <div>Проверьте Ваше подключение к Интернету и попробуйте снова.</div>
    </div>
    <button
      class="btn btn-danger btn-block btn-lg mb-3"
      onclick="window.location.href='/'"
    >
      Попробовать снова
    </button>
  </div>
</template>

<script>
export default {
  name: "OfflineIndex",
};
</script>
