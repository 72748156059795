<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Шаг 2/{{ stepCount }}: Пол</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-2">
        <img src="@/assets/image/start/2.jpg" width="107px" height="107px" />
      </div>
      <div class="list-group mb-3 text-center">
        <button
          v-on:click="genderMale"
          class="list-group-item list-group-item-action text-body"
        >
          Мужской
        </button>
        <button
          v-on:click="genderFemale"
          class="list-group-item list-group-item-action text-body"
        >
          Женский
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { startMixin } from "@/mixins/start";

export default {
  name: "StartGender",
  mixins: [startMixin],
  methods: {
    genderMale: function () {
      this.next(1);
    },
    genderFemale: function () {
      this.next(2);
    },
    next: function (gender) {
      this.routerPushWithCurrentQuery("/start/activity", { gender });
    },
    back: function () {
      this.routerPushWithCurrentQuery("/start/goal");
    },
  },
};
</script>
