<template>
  <div>
    <div class="p-2 mb-3 d-flex align-items-center" v-if="labelVisible">
      <b>{{ label }}</b>
      <b class="ml-auto" v-if="list.length > 1">
        {{ energyConsumedSum }} ккал
      </b>
    </div>
    <div v-for="(item, index) in list" v-bind:key="`journal-product-${index}`">
      <journal-product v-bind:product="item"></journal-product>
    </div>
  </div>
</template>

<script>
import JournalProduct from "@/components/journal/Product.vue";
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date.js";

export default {
  name: "JournalProductList",
  components: {
    JournalProduct,
  },
  props: ["list", "labelVisible"],
  mixins: [productMixin, dateMixin],
  computed: {
    date: function () {
      return this.$store.state.date;
    },
    energyConsumedSum: function () {
      let sum = 0;
      this.list.forEach(function (product) {
        sum +=
          (this.getEnergyConsumed(product.energyConsumed) / 100) *
          product.servingWeight;
      }, this);
      return Math.round(sum);
    },
    label: function () {
      let firstHour = Math.floor(this.list[0].time / 60);
      let lastHour = Math.floor(this.list[this.list.length - 1].time / 60);
      if (firstHour === lastHour) {
        return firstHour + ":00";
      }
      return firstHour + ":00 – " + (lastHour + 1) + ":00";
    },
  },
  methods: {
    addClick: function () {
      this.$router.push({
        path: "/product/select",
        query: {
          date: this.getDateString(this.date),
        },
      });
    },
  },
};
</script>
