<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Продление доступа</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div key="if" v-if="inited">
          <div class="p-2 mb-3" v-if="$route.query.from === 'product'">
            Срок действия доступа закончился, выберите тариф для продолжения.
          </div>
          <div class="list-group mb-3 text-center">
            <a
              v-for="(tariff, index) in tariffs"
              v-bind:key="index"
              v-bind:href="tariff.url"
              class="list-group-item list-group-item-action text-body"
              >{{ tariff.label }}</a
            >
          </div>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "AccountPayment",
  mixins: [apiMixin],
  data: function () {
    return {
      inited: false,
      loadSpinnerVisible: false,
      tariffs: null,
    };
  },
  methods: {
    back: function () {
      this.$router.push("/journal");
    },
  },
  mounted: function () {
    this.loadSpinnerVisible = false;
    let loadSpinnerTimer = setTimeout(() => {
      this.loadSpinnerVisible = true;
    }, 1000);
    this.apiGet("/api/account-api/get-tariffs")
      .then((response) => {
        if (response.data.status != "ok") {
          window.location.href = "/journal";
          return;
        }
        this.tariffs = response.data.data.tariffs;
        this.inited = true;
        clearTimeout(loadSpinnerTimer);
      })
      .catch(() => {
        window.location.href = "/journal";
      });
  },
};
</script>
