import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/site.css";
import "./registerServiceWorker";
import { createApp } from "vue";
const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
navigator.serviceWorker.ready.then((registration) => {
    registration.update();
    setInterval(() => {
        registration.update();
    }, 30000);
});
