<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Загрузка...</span>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "ClickTrack",
  mixins: [apiMixin],
  mounted: function () {
    let newUser = localStorage.getItem("accessToken") === null;
    this.apiPost("/api/click-api/track", {
      utmSource: this.$route.query.utm_source,
      utmMedium: this.$route.query.utm_medium,
      utmCampaign: this.$route.query.utm_campaign,
      utmContent: this.$route.query.utm_content,
      utmTerm: this.$route.query.utm_term,
      yclid: this.$route.query.yclid,
      newUser: newUser,
    });
    this.$router.push({
      path: "/start/goal",
      query: {
        first: 1,
      },
    });
  },
};
</script>
