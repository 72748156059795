<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <button
              class="text-decoration-none text-reset btn p-0"
              v-on:click="back"
            >
              <img
                src="@/assets/image/icon/chevron-left.svg"
                width="24px"
                height="24px"
              />
            </button>
            <b>Служба поддержки</b>
            <div style="width: 24px; height: 24px"></div>
          </div>
        </div>
      </div>
      <div>
        <form v-on:submit.prevent="sendSupportMessage">
          <div class="form-group mb-2">
            <div class="input-group">
              <textarea
                class="form-control"
                placeholder="Напишите сообщение"
                v-model="supportMessageText"
                rows="2"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn btn-primary btn-block">
              <span>Отправить</span>
            </button>
          </div>
        </form>
      </div>
      <div v-if="!isMessageListLoading || !isSpinnerVisible">
        <div v-for="(message, index) in messages" v-bind:key="index">
          <div class="p-2 mb-3">
            <b class="text-primary" v-if="message.isFromUser">Вы</b>
            <b class="text-danger" v-else>Служба поддержки</b>
            <br />
            <span class="text-muted">{{ message.dateTime }}</span>
            <p class="m-0">
              <span v-for="(line, index) in message.lines" v-bind:key="index">
                {{ line }}
                <br v-if="index < message.lines.length - 1" />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center" v-else>
        <div class="spinner-border" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiMixin } from "@/mixins/api";

export default {
  name: "AccountSupport",
  mixins: [apiMixin],
  data: function () {
    return {
      messages: [],
      supportMessageText: "",
      loadMessageInterval: null,
      lastId: null,
      isMessageListLoading: false,
      isSpinnerVisible: true,
    };
  },
  methods: {
    sendSupportMessage: function () {
      let message = this.supportMessageText;
      if (message === "") {
        return;
      }
      this.supportMessageText = "";
      this.loaded = false;
      this.apiPost("/api/support-message-api/send", {
        text: message,
      })
        .then((response) => {
          if (response.data.success !== true) {
            window.location.href = "/journal";
            return;
          }
          this.isSpinnerVisible = true;
          this.loadMessageList();
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    back: function () {
      this.$router.push("/account/cabinet");
    },
    loadMessageList: function () {
      if (this.isMessageListLoading) {
        return;
      }
      this.isMessageListLoading = true;
      this.apiGet("/api/support-message-api/load", {
        params: {
          lastId: this.lastId,
        },
      })
        .then((response) => {
          if (response.data.success !== true) {
            window.location.href = "/journal";
            return;
          }
          if (response.data.messages.length > 0) {
            this.lastId = response.data.lastId;
          }
          this.messages = response.data.messages
            .map(function (message) {
              message.lines = message.text.split("\n");
              return message;
            })
            .concat(this.messages);
          this.isSpinnerVisible = false;
          this.isMessageListLoading = false;
        })
        .catch(() => {
          window.location.href = "/journal";
        });
    },
    startLoadMessageListInterval() {
      this.stopLoadMessageListInterval();
      this.loadMessageInterval = setInterval(() => {
        this.loadMessageList(false);
      }, 2000);
    },
    stopLoadMessageListInterval() {
      if (this.loadMessageInterval === null) {
        return;
      }
      clearInterval(this.loadMessageInterval);
    },
  },
  mounted: function () {
    this.isSpinnerVisible = true;
    this.loadMessageList();
    this.startLoadMessageListInterval();
  },
  beforeUnmount() {
    this.stopLoadMessageListInterval();
  },
};
</script>
